<template>
  <fetch-data
    v-slot="{ pagination }"
    :url="url"
    :params="paginationQuery"
    :api-v2="apiV2"
  >
    <div class="text-gray-dark" :class="`text-${textSize}`">
      <span class="mr-1">
        {{
          filteredEntries
            ? $t('table-grid.entries', entriesParams)
            : $t('table-grid.no-entries')
        }}
      </span>
      <span v-if="pagination && pagination.total > filteredEntries">
        ({{ $t('table-grid.filtered', { total: pagination.total }) }})
      </span>
    </div>
  </fetch-data>
</template>
<script>
import FetchData from '@/components/DataProvider/FetchData';
import { mapQueryToApiV2 } from '@/util/query';

export default {
  components: {
    FetchData,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    filteredEntries: {
      type: Number,
      required: true,
    },
    initialQuery: {
      type: Object,
      default: () => {},
    },
    textSize: {
      type: String,
      default: 'xs',
      validate: (value) =>
        ['xxs', 'xs', 'sm', 'base', 'lg', 'xl'].includes(value),
    },
    apiV2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paginationQuery: {
        ...(this.apiV2
          ? mapQueryToApiV2(this.initialQuery)
          : this.initialQuery),
        limit: 1,
      },
    };
  },
  computed: {
    entriesParams() {
      return {
        from: this.from,
        to: Math.min(this.to, this.filteredEntries),
        total: this.filteredEntries,
      };
    },
  },
};
</script>
