<template>
  <span class="flex items-center gap-1 h-full">
    <span class="h-4 flex items-center text-sm font-semibold">
      {{ formatCurrency(params.cost) }}
    </span>
    <span v-if="params.summaryCell" class="font-semibold text-xxs leading-5">
      ({{ $t('offer-costs.total-contract-cost') }})
    </span>
  </span>
</template>

<script>
import { formatCurrency } from '@/util/currency';

export default {
  methods: { formatCurrency },
};
</script>
