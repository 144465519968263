import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import ObjectAcceptCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ObjectAcceptCell.vue';
import { createObjectInOfferDetailsRoute } from '@/router/utils';
import { useBrand } from '@/composables';
import { GenericAGTable } from '@/components/Table/AGTable/AGTable.vue';
import { useOfferObjectColumns } from '@/views/Brand/Applications/Offer/Details/composables/use-offer-object-columns';
import ObjectDecisionCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ObjectDecisionCell.vue';
import ObjectAddressCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ObjectAddressCell.vue';
import { getObjectsList } from '@/api/v2/requests/objects';
import NoResults from '@/views/_components/NoResults.vue';
import TablePaginationV2 from '@/views/_components/Table/TablePaginationV2.vue';
import AvailabilityCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/AvailabilityCell.vue';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import OfferExpandableCard from '@/views/Brand/Applications/Components/Offers/OfferExpandableCard.vue';
import FloatingTextFilter from '@/components/Table/AGTable/_components/FloatingTextFilter.vue';
import FloatingSelectFilter from '@/components/Table/AGTable/_components/FloatingSelectFilter.vue';
import { ClientRouteName } from '@/router/router.constants';
import ObjectNumberCell from '@/views/Brand/Applications/Components/Offers/ObjectNumberCell.vue';
import { useScrollElementIntoCenter } from '@/layouts/_composables/use-scroll-element-into-center';
const { useState } = createNamespacedHelpers('offer');
export default defineComponent({
    props: {
        highlightedObjectId: {
            type: String,
            default: null,
        },
    },
    components: {
        OfferExpandableCard,
        TablePaginationV2,
        TableLoader,
        NoResults,
        AgTable: GenericAGTable(),
        // eslint-disable-next-line vue/no-unused-components
        ObjectAcceptCell,
        // eslint-disable-next-line vue/no-unused-components
        ObjectDecisionCell,
        // eslint-disable-next-line vue/no-unused-components
        ObjectAddressCell,
        // eslint-disable-next-line vue/no-unused-components
        AvailabilityCell,
        // eslint-disable-next-line vue/no-unused-components
        FloatingTextFilter,
        // eslint-disable-next-line vue/no-unused-components
        FloatingSelectFilter,
        // eslint-disable-next-line vue/no-unused-components
        ObjectNumberCell,
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { currentOffer } = useState(['currentOffer']);
        const isLoading = ref(false);
        const rows = ref([]);
        const totalCount = ref();
        const getObjectRoute = (object) => {
            const { id, objectType } = object;
            if (!objectType || !brand.value || !currentOffer.value)
                return '';
            return createObjectInOfferDetailsRoute(brand.value.id, id, objectType.id, currentOffer.value.id);
        };
        const { columns } = useOfferObjectColumns(ctx, currentOffer.value);
        const url = computed(() => {
            return `objects`;
        });
        const page = ref(1);
        const objectQuery = computed(() => {
            return {
                page: page.value,
                offerId: currentOffer.value.id,
                perPage: 500,
            };
        });
        const fetchItems = async () => {
            isLoading.value = true;
            try {
                const { nodes, totalCount: nodesCount } = await getObjectsList(objectQuery.value ?? {});
                totalCount.value = nodesCount;
                rows.value = nodes;
            }
            catch (err) {
                ctx.root.$toast.error(ctx.root.$t(`offer-costs.error-fetching`));
            }
            finally {
                isLoading.value = false;
            }
        };
        onMounted(async () => {
            await fetchItems();
        });
        watch(page, async () => {
            await fetchItems();
        });
        const gridApi = ref(null);
        watch(() => props.highlightedObjectId, () => {
            if (gridApi.value) {
                // @ts-ignore
                gridApi.value.redrawRows();
            }
        });
        const { scrollIntoCenter } = useScrollElementIntoCenter();
        watch(() => props.highlightedObjectId, () => {
            if (props.highlightedObjectId) {
                const el = document.querySelector(`.ag-row[row-id="${props.highlightedObjectId}"]`);
                if (!el)
                    return;
                scrollIntoCenter(el);
            }
        });
        const getRowClass = (row) => {
            return row?.data?.id === props.highlightedObjectId
                ? 'bg-yellow-light'
                : '';
        };
        const getRowId = (row) => {
            return row.data.id;
        };
        return {
            ClientRouteName,
            url,
            columns,
            getObjectRoute,
            objectQuery,
            rows,
            currentOffer,
            totalCount,
            page,
            isLoading,
            getRowClass,
            getRowId,
            gridApi,
        };
    },
});
