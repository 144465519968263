import { LOCAL_STORAGE_KEY, useLocalStorage, } from '@/composables/use-local-storage';
import { computed } from '@vue/composition-api';
export const useCheckIfSurveysWereClosed = () => {
    const { storedValue, setStoredValue } = useLocalStorage(LOCAL_STORAGE_KEY.WAS_SURVEY_BANNER_CLOSED);
    const wereSurveysClosed = computed(() => {
        return storedValue.value === 'true';
    });
    const setSurveysClosed = (value) => setStoredValue(String(value));
    return {
        wereSurveysClosed,
        setSurveysClosed,
    };
};
