import { computed, defineComponent, ref } from '@vue/composition-api';
import { AgGridVue } from 'ag-grid-vue';
class AGTableGeneric {
    define() {
        return defineComponent({
            components: {
                AgGridVue,
            },
            props: {
                rows: {
                    type: Array,
                    default: () => [],
                },
                columns: {
                    type: Array,
                    required: true,
                },
                pinnedBottomRowData: {
                    type: Array,
                    default: () => [],
                },
                getRowClass: {
                    type: Function,
                },
                getRowId: {
                    type: Function,
                },
                rowHeight: {
                    type: Number,
                    default: 47,
                },
            },
            setup(props, ctx) {
                const displayedRowCount = ref(props.rows.length);
                const tableStyle = computed(() => {
                    const hasFilters = props.columns.some((column) => column.floatingFilter);
                    let base = 70;
                    if (hasFilters)
                        base += 50;
                    if (props.pinnedBottomRowData)
                        base += props.pinnedBottomRowData.length * props.rowHeight;
                    return {
                        height: `${base + displayedRowCount.value * props.rowHeight}px`,
                    };
                });
                const handleRowClick = ({ data }) => {
                    ctx.emit('row-clicked', data);
                };
                const handleRowHover = (params) => {
                    ctx.emit('row-hover-start', params.data);
                };
                const handleFilterChange = ({ api }) => {
                    displayedRowCount.value = api.getDisplayedRowCount();
                };
                return {
                    displayedRowCount,
                    handleRowClick,
                    handleRowHover,
                    tableStyle,
                    handleFilterChange,
                };
            },
        });
    }
}
const main = new AGTableGeneric().define();
export function GenericAGTable() {
    return main;
}
export default main;
