import Btn from '@/components/Button/Button.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
import EditSvg from '@/assets/icons/edit.svg';
import { mdiClose } from '@mdi/js';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { getSingleObject } from '@/api/v2/requests/objects';
export default defineComponent({
    components: {
        LoadingSpinner,
        Btn,
        ImageView,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        hidePreviewButton: Boolean,
    },
    setup(props) {
        const { isAdmin } = useAuthUser();
        const object = ref(null);
        const isLoading = ref(true);
        onMounted(async () => {
            object.value = await getSingleObject(props.id);
            isLoading.value = false;
        });
        return {
            EditSvg,
            mdiClose,
            ImageSize,
            isAdmin,
            object,
            isLoading,
        };
    },
});
