import { computed, defineComponent, watch, } from '@vue/composition-api';
import Pagination from '@/components/Pagination/Pagination.vue';
export default defineComponent({
    components: { Pagination },
    props: {
        query: {
            type: Object,
            required: true,
        },
        activePage: {
            type: Number,
            required: true,
        },
        totalCount: {
            type: Number,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        dataNotEmpty: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const lastPage = computed(() => {
            return Math.ceil(props.totalCount / props.query.perPage);
        });
        const changePage = (e) => {
            ctx.emit('change-page', e);
        };
        watch(() => props.query, (value) => {
            if (value && value.page > lastPage.value) {
                changePage(1);
            }
        }, {
            deep: true,
            immediate: true,
        });
        return {
            changePage,
            lastPage,
        };
    },
});
