var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-2/5 mt-8"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"placeholder":_vm.$t('forms.name'),"label":_vm.$t('forms.set-name'),"error-message":errors[0],"required":"","disabled":_vm.loading},on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}])})],1),_c('div',{staticClass:"w-2/5 mt-6 relative"},[(_vm.mode === _vm.FORM_MODE.EDIT)?_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.categories && _vm.category)?_c('select-input',{staticClass:"mb-4",attrs:{"value":_vm.category,"label":_vm.$t('forms.category'),"options":_vm.categories.results,"option-label-name":"name","option-value-name":"id","error-message":errors[0],"required":"","disabled":""}}):_vm._e()]}}],null,false,3061562556)}):_vm._e(),(_vm.formType === _vm.FORM_TYPE.REPORT)?[_c('select-input',{staticClass:"mb-2",attrs:{"id":"decision-makers-select","url":"users","query":{ brandId: _vm.brandId },"label":_vm.$t('forms.decision-makers'),"placeholder":_vm.$t('common.select'),"option-value-name":"id","disabled":_vm.loading,"multiple":"","autocomplete":"","clearable":""},on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}],null,false,4221460457),model:{value:(_vm.decisionMakers),callback:function ($$v) {_vm.decisionMakers=$$v},expression:"decisionMakers"}}),_c('select-input',{attrs:{"id":"employees-select","url":"users","query":_vm.employeesQuery,"label":_vm.$t('forms.employee-users'),"placeholder":_vm.$t('common.select'),"option-value-name":"id","disabled":_vm.loading,"clearable":"","autocomplete":"","multiple":""},on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}],null,false,4221460457),model:{value:(_vm.employees),callback:function ($$v) {_vm.employees=$$v},expression:"employees"}}),_c('div',{staticClass:"flex flex-col mt-3.5 gap-5"},[_c('div',{staticClass:"flex"},[_c('checkbox',{on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},model:{value:(_vm.mustContainObjects),callback:function ($$v) {_vm.mustContainObjects=$$v},expression:"mustContainObjects"}}),_c('p',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t('forms.contain-objects')))])],1),_c('div',{staticClass:"flex"},[_c('checkbox',{on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},model:{value:(_vm.mustContainPlaces),callback:function ($$v) {_vm.mustContainPlaces=$$v},expression:"mustContainPlaces"}}),_c('p',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t('forms.contain-places')))])],1)])]:_vm._e()],2),_c('form-creator',{attrs:{"disabled":_vm.loading},on:{"input":function($event){return _vm.$emit('set-values', _vm.formValues)}},model:{value:(_vm.components),callback:function ($$v) {_vm.components=$$v},expression:"components"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }