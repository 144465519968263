import { defineComponent } from '@vue/composition-api';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
export default defineComponent({
    components: { FilterToggler },
    props: {
        actions: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: String,
            required: true,
        },
    },
});
