import { addPlacesToReport, addReportMessage, removeUsersFromReport, removePlacesFromReport, MAX_ASSET_REQUEST_SIZE, updateReportMessage, updateReportUsers, } from '@/api/requests/reports';
import { getPropertyArray } from '@/util/formatters';
import chunk from 'lodash.chunk';
import { addObjectsToReport, removeObjectsFromReport, } from '@/api/v2/requests/reports';
const getReportAssetRequests = (requests) => {
    const result = [];
    requests.forEach(({ ids, ...request }) => {
        if (ids?.length) {
            result.push(...chunk(ids, MAX_ASSET_REQUEST_SIZE).map((chunk) => ({
                ids: chunk,
                ...request,
            })));
        }
    });
    return result;
};
export const handleReportResources = async (reportId, { objects, places, removedObjectIds, removedPlaceIds }) => {
    const resourceRequests = [
        {
            ids: getPropertyArray(objects, 'id'),
            handler: addObjectsToReport,
            name: 'objectIds',
        },
        {
            ids: getPropertyArray(places, 'id'),
            handler: addPlacesToReport,
            name: 'placeIds',
        },
        {
            ids: removedObjectIds,
            handler: removeObjectsFromReport,
            name: 'objectIds',
        },
        {
            ids: removedPlaceIds,
            handler: removePlacesFromReport,
            name: 'placeIds',
        },
    ];
    await Promise.all(getReportAssetRequests(resourceRequests)
        .filter(({ name }) => name)
        .map(({ ids, handler, name }) => handler(reportId, {
        [name]: ids,
    })));
};
export const handleReportReceivers = async ({ reportId, users, removedUserIds, decisionMakerIds, }) => {
    const parsedUsers = getPropertyArray(users, 'id').map((userId) => {
        return { userId, isDecisionMaker: decisionMakerIds?.includes(userId) };
    });
    const userRequests = [
        {
            ids: parsedUsers,
            handler: updateReportUsers,
            name: 'users',
        },
        {
            ids: removedUserIds,
            handler: removeUsersFromReport,
            name: 'userIds',
        },
    ];
    await Promise.all(getReportAssetRequests(userRequests).map(({ handler, ids, name }) => handler(reportId, { [name]: ids })));
};
export const addReportDescription = async ({ reportId, message, fileTokens, }) => {
    await addReportMessage({
        reportId,
        message: message.trim(),
        objectIds: [],
        placeIds: [],
        fileTokens,
    });
};
export const updateReportDescription = async ({ message, fileTokens, description, }) => {
    await updateReportMessage(message.id, { message: description, fileTokens });
};
