import api from '@/api';
import apiV2 from '@/api/v2';

export const fetchAllItems = async (
  url,
  params = {},
  fetched = [],
  useApiV2 = false,
) => {
  const { limit, perPage, ...queryParams } = params;
  const perPageLimit = limit ?? perPage ?? 100;
  const apiInstance = useApiV2 ? apiV2 : api;
  const perPageProperty = useApiV2 ? 'perPage' : 'limit';

  const { data } = await apiInstance.get(url, {
    params: { ...queryParams, [perPageProperty]: perPageLimit },
  });

  const results = useApiV2 ? data.nodes : data.results;
  const currentPage = useApiV2 ? params.page || 1 : data.pagination.currentPage;
  const to = useApiV2 ? (currentPage + 1) * perPageLimit : data.pagination.to;
  const total = useApiV2 ? data.totalCount : data.pagination.total;

  const fetchedData = [...fetched, ...results];

  if (to >= total) {
    return fetchedData;
  }

  return fetchAllItems(
    url,
    { ...params, page: currentPage + 1 },
    fetchedData,
    useApiV2,
  );
};
