import VueStore from '@/store';

export const fetchObjectData = async (path, offer = false) => {
  const activeOfferId = VueStore.getters['offer/activeOfferId'];

  await Promise.all([
    VueStore.dispatch('object/fetchObjectDetails', {
      id: path.params['objectId'],
      offerId: offer ? activeOfferId : undefined,
    }),
  ]);

  const currentObjectType = VueStore.state.object.objectType;

  const objectType = VueStore.state.object.details?.objectType;

  const shouldFetchObjectTypeData =
    objectType &&
    (!currentObjectType || currentObjectType.id !== objectType.id);

  if (shouldFetchObjectTypeData)
    await VueStore.dispatch('object/fetchObjectTypeDetails', objectType.id);
};

export const fetchOfferData = async (path) => {
  const { offerId, objectId } = path.params;

  const activeOfferId = VueStore.getters['offer/activeOfferId'];

  if (!activeOfferId) await VueStore.dispatch('offer/fetchOffer', offerId);

  await VueStore.dispatch('offer/setActiveObject', objectId);

  // TODO: refactor - util for changing previewed items
  if (offerId !== activeOfferId) {
    await VueStore.dispatch('offer/clearOfferObjectsList');
    await VueStore.dispatch('offer/fetchAllOfferObjects', {
      offerId,
      brandId: VueStore.getters['brand/currentBrandId'](path),
    });
  }
};
