// @ts-ignore
import resize from 'vue-resize-directive';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { Btn, BtnTheme } from '@/components/Button';
import { calcTextWidth } from '@/util/text';
import AllObjectsModal from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/AllObjectsModal.vue';
import ObjectWithPopover from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/ObjectWithPopover.vue';
import { PortalTarget } from '@/constants/portal';
export default defineComponent({
    name: 'ChatReportObjectsList',
    components: {
        ObjectWithPopover,
        AllObjectsModal,
        Btn,
    },
    directives: {
        resize,
    },
    props: {
        reportId: {
            type: String,
            required: true,
        },
        reportObjects: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const listArea = ref(null);
        const displayWidth = ref(0);
        const buttonWidth = ref(0);
        const showAllObjects = ref(false);
        const objectsToDisplay = computed(() => {
            let count = 0;
            let currentWidth = 0;
            const font = listArea.value
                ? getComputedStyle(listArea.value).font
                : getComputedStyle(document.body).font;
            for (const object of props.reportObjects.nodes) {
                const placeNameWidth = calcTextWidth(`${object.internalNumber}, `, font);
                if (placeNameWidth + currentWidth + buttonWidth.value + 20 >
                    displayWidth.value) {
                    break;
                }
                count += 1;
                currentWidth += placeNameWidth;
            }
            return props.reportObjects.nodes.slice(0, count);
        });
        const additionalObjectsCount = computed(() => {
            return props.reportObjects.totalCount - objectsToDisplay.value.length;
        });
        const onDisplayResize = (element) => {
            displayWidth.value = element.clientWidth;
        };
        const onButtonResize = (element) => {
            buttonWidth.value = element.clientWidth;
        };
        return {
            BtnTheme,
            listArea,
            onDisplayResize,
            onButtonResize,
            objectsToDisplay,
            additionalObjectsCount,
            showAllObjects,
            PortalTarget,
        };
    },
});
