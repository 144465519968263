<template>
  <span class="flex items-center h-full">
    <span
      v-if="params.centralStatus"
      class="px-2 uppercase font-semibold text-3xs h-5 flex items-center rounded-md leading-1"
      :class="color"
    >
      {{ $t(`offer-costs.decision.${params.centralStatus}`) }}
    </span>
  </span>
</template>

<script>
import { OfferAcceptanceStatus } from '@/api/v2/requests/offers';

export default {
  computed: {
    color() {
      switch (this.params.centralStatus) {
        case OfferAcceptanceStatus.ACCEPTED:
          return 'bg-green-lightest text-green';
        case OfferAcceptanceStatus.REJECTED:
          return 'bg-red-lightest text-red';
        case OfferAcceptanceStatus.REVERTED:
          return 'bg-gray-lightest text-black';
        default:
          return '';
      }
    },
  },
};
</script>
