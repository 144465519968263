import { computed, defineComponent, onBeforeUnmount, onMounted, ref, toRefs, watch, } from '@vue/composition-api';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { useClusteredMap } from '@/components/Map/composables/use-clustered-map';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { useMapRoutes } from '@/components/Map/composables/use-map-routes';
import { useInteractiveMap } from '@/components/Map/composables/use-interactive-map';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';
import { useMapControls } from '@/components/Map/composables/use-map-controls';
import { useMapState } from '@/components/Map/composables/use-map-state';
import { useDataSetsDelivery } from '@/components/Map/composables/use-data-sets-delivery';
export default defineComponent({
    components: { LoadingSpinner, FetchData },
    props: {
        value: {
            type: Object,
            default: null,
        },
        interactive: Boolean,
        interactiveMarker: Object,
        dataSets: Array,
        routeStart: Object,
        routeEnd: Object,
        activeMarkerId: {
            type: String,
            default: null,
        },
        disabledSettings: {
            type: Array,
            default: () => [],
        },
        height: {
            type: String,
            default: 'h-180',
        },
        spacing: {
            type: String,
            default: 'mt-5',
        },
        apiV2: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const { dataSets } = toRefs(props);
        const mapId = ref(uuidv4());
        const { settings } = useMapState();
        const { disabledSettings } = props;
        const { map, isMapLoaded, initializeMap, setMarkers, initializeMarkerClusterer, clearMapListeners, clearMarkersListeners, setMapListeners, highlightActiveMarker, } = useClusteredMap(ctx);
        watch(() => props.activeMarkerId, () => highlightActiveMarker(props.activeMarkerId, props.dataSets));
        const { initializeMapControls, clearControlsListeners } = useMapControls({
            map,
            disabledSettings,
        });
        const { clearInteractiveMapListeners, initializeInteractiveMarker, setInteractiveMarker, } = useInteractiveMap({ map }, ctx);
        const { initializeDirections, calculateAndDisplayRoute } = useMapRoutes({
            map,
        });
        const { areAllDataSetsDelivered, handleDataDeliver, handleDataPending } = useDataSetsDelivery({ dataSets, setMarkers }, ctx);
        const route = computed(() => ({
            origin: props.routeStart?.position,
            destination: props.routeEnd?.position,
        }));
        const showRoute = computed(() => route.value.destination && route.value.origin);
        const handleCalculateAndDisplayRoute = () => {
            if (props.routeStart && props.routeEnd) {
                calculateAndDisplayRoute(props.routeStart, props.routeEnd);
            }
        };
        onMounted(async () => {
            await initializeMap(mapId.value);
            initializeMapControls();
            if (props.interactive) {
                await initializeInteractiveMarker(props.value, props.interactiveMarker);
            }
            if (showRoute.value) {
                initializeDirections();
                await handleCalculateAndDisplayRoute();
                return;
            }
            await initializeMarkerClusterer();
            await setMapListeners();
        });
        watch(() => ({
            route,
            routeStartMarkerColor: props.routeStart?.markerColor,
            settings,
        }), () => handleCalculateAndDisplayRoute(), { deep: true });
        watch(() => ({
            value: props.value,
            marker: props.interactiveMarker,
        }), (value, oldValue) => {
            if (isEqual(value, oldValue)) {
                return;
            }
            setInteractiveMarker(props.value, props.interactiveMarker);
        }, { deep: true });
        onBeforeUnmount(() => {
            clearMapListeners();
            clearMarkersListeners();
            clearInteractiveMapListeners();
            clearControlsListeners();
        });
        return {
            mapId,
            handleDataDeliver,
            handleDataPending,
            areAllDataSetsDelivered,
            isMapLoaded,
        };
    },
});
