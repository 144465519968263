import { computed } from '@vue/composition-api';
import { OfferAcceptanceType, OfferAcceptanceStatus, } from '@/api/v2/requests/offers';
import { useCommonColumns } from '@/views/Brand/Applications/Offer/Details/composables/use-common-columns';
export const useOfferObjectColumns = (ctx, offer) => {
    const { commonColumns } = useCommonColumns(ctx, {
        offer,
        useOfferObjectDetails: true,
    });
    const columns = computed(() => {
        const acceptanceColumns = [
            {
                field: 'statuses',
                colId: 'decision',
                headerName: ctx.root.$t('offer-costs.decisionLabel').toString(),
                width: 200,
                cellRenderer: 'ObjectDecisionCell',
                cellRendererParams: (params) => {
                    return {
                        centralStatus: params?.value,
                    };
                },
                pinned: 'right',
                suppressMovable: true,
                valueGetter: (params) => {
                    const centralStatus = (params?.data?.statuses ?? []).find((status) => status.type === OfferAcceptanceType.CENTRAL);
                    return centralStatus?.status;
                },
                filter: true,
                floatingFilter: true,
                suppressHeaderMenuButton: true,
                floatingFilterComponent: 'FloatingSelectFilter',
                floatingFilterComponentParams: () => ({
                    id: 'decision-select',
                    options: [
                        OfferAcceptanceStatus.ACCEPTED,
                        OfferAcceptanceStatus.REJECTED,
                        OfferAcceptanceStatus.REVERTED,
                    ].map((value) => ({ value, label: value })),
                    getLabel: (value) => {
                        return ctx.root.$t(`offer-costs.decision.${value}`);
                    },
                }),
                suppressFloatingFilterButton: true,
            },
            {
                field: 'statuses',
                colId: 'centralStatus',
                sortable: false,
                headerName: ctx.root.$t('offer-costs.central').toString(),
                width: 100,
                cellRenderer: 'ObjectAcceptCell',
                cellRendererParams: (params) => {
                    return {
                        level: OfferAcceptanceType.CENTRAL,
                        offerId: offer.id,
                        statuses: params?.data.statuses,
                        objectId: params?.data.id,
                    };
                },
                pinned: 'right',
                suppressMovable: true,
            },
            {
                field: 'statuses',
                colId: 'structureStatus',
                sortable: false,
                width: 100,
                headerName: ctx.root.$t('offer-costs.structure').toString(),
                cellRenderer: 'ObjectAcceptCell',
                cellRendererParams: (params) => {
                    return {
                        level: OfferAcceptanceType.STRUCTURE,
                        offerId: offer.id,
                        statuses: params?.data.statuses,
                        objectId: params?.data.id,
                    };
                },
                pinned: 'right',
                suppressMovable: true,
            },
            {
                field: 'statuses',
                colId: 'placeStatus',
                sortable: false,
                width: 100,
                headerName: ctx.root.$t('offer-costs.place').toString(),
                cellRenderer: 'ObjectAcceptCell',
                cellRendererParams: (params) => {
                    return {
                        level: OfferAcceptanceType.PLACE,
                        offerId: offer.id,
                        statuses: params?.data.statuses,
                        objectId: params?.data.id,
                    };
                },
                pinned: 'right',
                suppressMovable: true,
            },
        ];
        return [
            ...commonColumns.value,
            ...(offer.isApprovalRequired ? acceptanceColumns : []),
        ];
    });
    return { columns };
};
