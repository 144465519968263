import RoleAccept from '@/views/Brand/Objects/_components/Offer/RoleAccept.vue';
import { ACCEPT_BUTTON_TYPES } from '@/store/modules/user';
import { OfferObjectStatus } from '@/constants/offer-object';
import { computed, defineComponent } from '@vue/composition-api';
import { AcceptanceLevel } from '@/api/requests/offers';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
export default defineComponent({
    name: 'ObjectAccept',
    components: {
        LoadingSpinner,
        RoleAccept,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        loading: Boolean,
    },
    setup() {
        const acceptButtons = computed(() => [
            AcceptanceLevel.CENTRAL,
            AcceptanceLevel.STRUCTURE,
            AcceptanceLevel.PLACE,
        ]);
        return {
            ACCEPT_BUTTON_TYPES,
            OfferObjectStatus,
            AcceptanceLevel,
            acceptButtons,
        };
    },
});
