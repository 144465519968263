import { computed, defineComponent, ref } from '@vue/composition-api';
import { GenericTableHeader } from '@/components/Table/__new__/_components/TableHeaderGeneric.vue';
import { GenericTableCell } from '@/components/Table/__new__/_components/TableCellGeneric/TableCellGeneric.vue';
import { useMobileView } from '@/composables';
class TableGeneric {
    define() {
        return defineComponent({
            components: {
                TableHeader: GenericTableHeader(),
                TableCell: GenericTableCell(),
            },
            props: {
                rows: {
                    type: Array,
                    default: () => [],
                },
                columns: {
                    type: Array,
                    required: true,
                },
                sort: {
                    type: Object,
                },
                isRowExpanded: {
                    type: Function,
                    default: () => false,
                },
                isRowClickable: {
                    type: Boolean,
                    default: () => true,
                },
                rowSize: {
                    type: String,
                },
                tableStyle: String,
                rowClass: {
                    type: [Function, String],
                    default: 'bg-white',
                },
                to: {
                    type: Function,
                },
            },
            setup(props, ctx) {
                const { isMobileView } = useMobileView();
                const computedColumns = computed(() => props.columns.filter(({ hideInMobile }) => {
                    return !(isMobileView.value && hideInMobile);
                }));
                const computedStyles = computed(() => ({
                    gridTemplateColumns: computedColumns.value
                        .map(({ gridColumn }) => gridColumn || 'auto')
                        .join(' '),
                }));
                const highlightedRow = ref(-1);
                const handleMouseEnterRow = (row, rowIndex) => {
                    highlightedRow.value = rowIndex;
                    ctx.emit('mouse-enter-row', row);
                };
                const handleMouseLeaveRow = (row, event) => {
                    const targetFrom = event.target;
                    const targetTo = event.relatedTarget;
                    if (targetFrom?.id === targetTo?.id) {
                        return;
                    }
                    highlightedRow.value = -1;
                    ctx.emit('mouse-leave-row', row);
                };
                const getCellClass = (row, index) => {
                    return [
                        { hover: highlightedRow.value === index },
                        typeof props.rowClass === 'string'
                            ? props.rowClass
                            : props.rowClass(row),
                    ];
                };
                return {
                    isMobileView,
                    computedStyles,
                    highlightedRow,
                    computedColumns,
                    handleMouseEnterRow,
                    handleMouseLeaveRow,
                    getCellClass,
                };
            },
        });
    }
}
const main = new TableGeneric().define();
export function GenericTable() {
    return main;
}
export default main;
