import DashboardLayout from '@/layouts/DashboardLayout/DashboardLayout.vue';

import { REGULAR } from '@/store/modules/user';
import { providePagination } from '@/util/routing';
import { PAGE_404 } from '@/router/routes/static.route';

export default [
  {
    path: '/dashboard',
    component: DashboardLayout,
    meta: {
      isAuthRequired: true,
      requiredRole: REGULAR,
    },
    redirect: PAGE_404,
    children: [
      {
        path: 'clients',
        name: 'Clients',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "Clients" */ '@/views/Dashboard/Clients/Clients.vue'
          ),
      },
      {
        path: 'clients/:id/details',
        name: 'ClientDetails',
        props: providePagination,
        component: () =>
          import(
            /* webpackChunkName: "ClientDetails" */ '@/views/Dashboard/Clients/ClientDetails.vue'
          ),
        meta: {
          matchName: 'Clients',
        },
      },
    ],
  },
];
