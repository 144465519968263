<template>
  <div class="h-full flex items-center pt-2">
    <SelectInput
      :key="params.id"
      v-model="currentValue"
      :options="options"
      clearable
      append-to-body
    />
  </div>
</template>

<script>
import { SelectInput } from '@/components/Inputs';

export default {
  components: { SelectInput },
  data: function () {
    return {
      currentValue: null,
    };
  },
  computed: {
    options() {
      return this.params.options.map((option) => ({
        ...option,
        label: this.params.getLabel
          ? this.params.getLabel(option.value)
          : option.label,
      }));
    },
  },
  methods: {
    onInputBoxChanged() {
      this.params.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged('equals', this.currentValue?.value);
      });
    },
  },
  watch: {
    currentValue() {
      this.onInputBoxChanged();
    },
    options(value) {
      this.currentValue = value.find(
        (option) => option.value === this.currentValue.value,
      );
    },
  },
};
</script>
