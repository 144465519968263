<template>
  <span class="flex items-center h-full">
    <status-btn
      v-if="offerObjectStatus && status !== OfferObjectStatus.REVERTED"
      :can-user-revert-action="canUserRevertAction"
      :loading="loading"
      :status="status"
      :user="user"
      :date="formattedDate"
      @revert="
        runActionWithModal(
          $t('object-details-in-offer-view.confirm-object-revert'),
          revert,
        )
      "
    />
    <decision-btns
      v-else-if="canUserTakeAction"
      @accept="
        runActionWithModal(
          $t('object-details-in-offer-view.confirm-object-accept'),
          accept,
        )
      "
      @reject="
        runActionWithModal(
          $t('object-details-in-offer-view.confirm-object-reject'),
          reject,
        )
      "
    />
    <span v-else class="no-action">
      {{ $t(`object-details-in-offer-view.no-action`) }}
    </span>

    <portal v-if="showConfirmModal && modalContent" to="modals">
      <confirm-modal
        :show="showConfirmModal"
        :title="modalContent.title"
        @confirm="modalContent.action"
        @close="onCloseModal"
      />
    </portal>
  </span>
</template>

<script>
import ConfirmModal from '@/components/Modal/__new__/ConfirmModal.vue';
import DecisionBtns from '@/views/Brand/Applications/Components/Offers/Costs/_components/DecisionBtns.vue';
import StatusBtn from '@/views/Brand/Applications/Components/Offers/Costs/_components/StatusBtn.vue';
import useObjectAccept from '@/mixins/useObjectAccept';
import { OfferAcceptanceStatus } from '@/api/v2/requests/offers';

export default {
  computed: {
    OfferObjectStatus() {
      return OfferAcceptanceStatus;
    },
  },
  components: { StatusBtn, DecisionBtns, ConfirmModal },
  // FIXME: use composition api after migration to Vue 3
  mixins: [useObjectAccept],
};
</script>

<style scoped lang="scss">
.no-action {
  @apply text-xs uppercase w-16 block whitespace-normal leading-none;
}
</style>
