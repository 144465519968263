import { defineComponent, ref } from '@vue/composition-api';
import { BTN_THEMES } from '@/store/modules/ui';
import { QuestionType, } from '@/api/v2/requests/surveys';
import Modal from '@/components/Modal/Modal.vue';
import { Btn } from '@/components/Btn';
import SurveyQuestion from './SurveyQuestion.vue';
import { useForm } from '@/composables';
import { submitSurveyAnswers } from '@/api/v2/requests/surveys';
import cloneDeep from 'lodash.clonedeep';
export default defineComponent({
    name: 'SurveyModal',
    components: {
        Modal,
        SurveyQuestion,
        Btn,
    },
    props: {
        survey: {
            type: Object,
            required: true,
        },
        show: Boolean,
    },
    setup(props, ctx) {
        const referenceForm = ref(cloneDeep({
            id: props.survey.id,
            questions: props.survey.questions,
        }));
        const { form, hasUnsavedChanges } = useForm(referenceForm);
        const getAnswer = (question) => {
            if (question.type === QuestionType.CHECKBOX) {
                return question
                    .options.filter((option) => option.isSelected)
                    .map((option) => option.label)
                    .join(';;;');
            }
            else {
                return question.value;
            }
        };
        const updateShow = () => {
            ctx.emit('close');
        };
        const handleSubmit = async (e) => {
            e.preventDefault();
            const answers = form.value.questions.map(getAnswer);
            const areRequiredFieldsFilled = !answers.some((answer, idx) => form.value.questions[idx].isRequired && !answer);
            if (!areRequiredFieldsFilled) {
                ctx.root.$toast.error(ctx.root.$t('surveys.survey-required-fields-error'));
                return;
            }
            ctx.emit('save', form.value.id);
            await submitSurveyAnswers(form.value.id, answers);
        };
        return {
            form,
            hasUnsavedChanges,
            BTN_THEMES,
            getAnswer,
            updateShow,
            handleSubmit,
        };
    },
});
