import { mapActions, mapGetters } from 'vuex';
import { FILTER_DEBOUNCE, FILTER_PARAMS } from '@/constants/filters';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '@/constants/pagination';

export default {
  props: {
    routeComponent: {
      type: String,
      default: '',
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    currentPage: {
      type: Number,
      default: DEFAULT_PAGE,
    },
    perPage: {
      type: [Number, String],
      default: DEFAULT_LIMIT,
    },
  },
  data() {
    return {
      FILTER_DEBOUNCE,
      activePage: this.currentPage,
    };
  },
  computed: {
    ...mapGetters('routes', ['routeQuery', 'pagination']),
    computedRouteQuery() {
      return this.routeQuery(this.$route);
    },
    computedQuery() {
      return {
        ...(this.routeComponent
          ? this.computedRouteQuery
          : {
              limit: parseInt(this.perPage),
              page: this.activePage,
              ...(this.sortByQuery && { sortBy: this.sortByQuery }),
            }),
        ...this.query,
      };
    },
    computedActivePage() {
      if (this.routeComponent) return this.pagination(this.$route)?.page || 1;
      return this.activePage;
    },
  },
  watch: {
    currentPage(value) {
      this.activePage = value;
    },
    perPage() {
      if (!this.routeComponent) {
        this.activePage = 1;
      }
    },
    pagination(pagination) {
      if (this.routeComponent && pagination?.page)
        this.activePage = pagination.page;
    },
    sortByQuery(value) {
      if (this.routeComponent) {
        this.updateFilter({
          route: this.$route,
          parameter: FILTER_PARAMS.SORT_BY,
          value: { value },
        });
      }
    },
    computedRouteQuery: {
      handler(value) {
        if (this.routeComponent) {
          this.sortByQuery = value?.[FILTER_PARAMS.SORT_BY] || null;
        }
        if (value.page) this.activePage = value.page;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions('routes', ['setRoutePagination', 'updateFilter']),
    changePage(val) {
      if (this.routeComponent) {
        this.setRoutePagination({
          route: this.$route,
          pagination: { page: val },
        });
      } else {
        this.activePage = val;
      }
    },
  },
};
