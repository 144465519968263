import { render, staticRenderFns } from "./BrandLayout.vue?vue&type=template&id=4c53d5b4&scoped=true&"
import script from "./BrandLayout.vue?vue&type=script&lang=ts&"
export * from "./BrandLayout.vue?vue&type=script&lang=ts&"
import style0 from "./BrandLayout.vue?vue&type=style&index=0&id=4c53d5b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c53d5b4",
  null
  
)

export default component.exports