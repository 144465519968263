<template>
  <div class="h-full flex items-center pt-2">
    <TextInput v-model="currentValue" @input="onInputBoxChanged" />
  </div>
</template>

<script>
import { TextInput } from '@/components/Inputs';

export default {
  components: { TextInput },
  data: function () {
    return {
      currentValue: null,
    };
  },
  methods: {
    onInputBoxChanged() {
      this.params.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(
          this.params.compare ?? 'greaterThan',
          this.currentValue,
        );
      });
    },
  },
};
</script>
