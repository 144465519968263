import { computed } from '@vue/composition-api';
import { useCommonColumns } from '@/views/Brand/Applications/Offer/Details/composables/use-common-columns';
export const useReservationColumns = (ctx, offer) => {
    const { commonColumns } = useCommonColumns(ctx, { offer });
    const columns = computed(() => {
        return [
            ...commonColumns.value,
            {
                field: 'isRejected',
                headerName: ctx.root.$t('offer-costs.decisionLabel').toString(),
                maxWidth: 200,
                flex: 1,
                cellRenderer: 'ReservationDecisionCell',
                cellRendererParams: (params) => {
                    return {
                        isRejected: params?.value,
                    };
                },
                valueGetter: (params) => {
                    return params?.data?.isRejected ?? '';
                },
                pinned: 'right',
                suppressMovable: true,
            },
        ];
    });
    return { columns };
};
