import { convertDMS, link } from '@/util/formatters';
import ExpandableRowPreview from '@/components/Table/ExpandableRowPreview.vue';
import { SelectInput } from '@/components/Inputs';
import difference from 'lodash.difference';
import CreateReportWithObjectButton from '@/views/Brand/Objects/_components/create-report-with-object-button/create-report-with-object-button.vue';
import { BtnTheme } from '@/components/Btn';
import { useObjectTags } from '@/composables/use-object-tags';
import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { useBrand } from '@/composables';
import { fetchAllItems } from '@/util/api';
export default defineComponent({
    components: {
        CreateReportWithObjectButton,
        ExpandableRowPreview,
        SelectInput,
    },
    props: {
        objectTypeId: {
            type: String,
            default: '',
        },
        objectId: {
            type: String,
            default: '',
        },
        hideDetailsBtn: {
            type: Boolean,
            default: false,
        },
        sidebar: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const { createNewTag, saveTag, removeTag, tags } = useObjectTags();
        const { brand } = useBrand();
        const getAddress = (data) => {
            const { postalCode, city, address } = data;
            return `${address}, ${postalCode || ''} ${city}`;
        };
        const allTags = ref([]);
        watch(tags, (value) => {
            if (value)
                allTags.value = value;
        });
        const handleTagsUpdate = (event) => {
            const newTags = difference(event, allTags.value);
            const removedTags = difference(allTags.value, event);
            newTags.forEach((tag) => {
                saveTag(tag, props.objectId, false);
            });
            removedTags.forEach((tag) => {
                removeTag(tag, props.objectId);
            });
        };
        const handleEnter = (value) => {
            createNewTag(value, props.objectId);
        };
        onMounted(async () => {
            try {
                tags.value = await fetchAllItems('/object-tags', { objectId: props.objectId }, [], true);
            }
            catch (e) {
                ctx.root.$toast(ctx.root.$t('object-preview.error-fetching-tags'));
            }
        });
        const objectProperties = computed(() => {
            return [
                {
                    label: ctx.root.$t('object-details-view.dimensions'),
                    key: 'dimensions',
                    getter: (data) => data.width && data.height
                        ? `${data.width} x ${data.height} cm`
                        : '-',
                },
                {
                    label: ctx.root.$t('object-details-view.production-dimensions'),
                    key: 'dimensions',
                    getter: (data) => data.width && data.height
                        ? `${data.productionWidth} x ${data.productionHeight} cm`
                        : '-',
                },
                {
                    label: ctx.root.$t('object-details-view.light'),
                    getter: (data) => ctx.root.$t(`object-details-view.${data.isLit ? 'yes' : 'no'}`),
                },
                {
                    label: ctx.root.$t('object-details-view.format'),
                    key: 'format',
                },
                {
                    label: ctx.root.$t('object-details-view.material'),
                    key: 'material',
                },
                {
                    label: ctx.root.$t('object-details-view.type'),
                    key: 'type',
                },
            ];
        });
        return {
            BtnTheme,
            convertDMS,
            link,
            getAddress,
            handleTagsUpdate,
            handleEnter,
            tags,
            brand,
            objectProperties,
        };
    },
});
