import CenteredLayout from '@/layouts/CenteredLayout.vue';
import ObjectInfo from '@/views/Brand/Objects/Components/ObjectInfo.vue';
import ObjectMap from '@/views/Brand/Objects/Components/ObjectMap.vue';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel.vue';
import PriceBox from '@/views/Brand/Objects/Components/PriceBox.vue';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';
import CreateReportWithObjectButton from '@/views/Brand/Objects/_components/create-report-with-object-button/create-report-with-object-button.vue';
import { BtnTheme } from '@/components/Btn';
import { computed, defineComponent } from '@vue/composition-api';
import { useObjects } from '@/composables';
export default defineComponent({
    components: {
        CreateReportWithObjectButton,
        PriceBox,
        CenteredLayout,
        ObjectMap,
        ObjectInfo,
        ImageCarousel,
    },
    props: {
        offerObject: {
            type: Boolean,
            default: false,
        },
        placePreview: {
            type: String,
            default: ObjectDetailsPlacePreview.POPOVER,
        },
        isModalChildren: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { object, objectType } = useObjects();
        const computedImages = computed(() => {
            if (!object.value.logoUrl)
                return [];
            return [
                {
                    url: object.value.logoUrl,
                    name: 'object image',
                },
            ];
        });
        return { object, objectType, BtnTheme, computedImages };
    },
});
