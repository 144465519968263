import Vue from 'vue';
import VueRouter from 'vue-router';
import VueStore from '@/store';
import { handleAuthenticatedUserAccess } from '@/router/routerUtils';
import AdminRoute from '@/router/routes/admin.route';
import ClientRoute from '@/router/routes/client.route';
import StaticRoute from '@/router/routes/static.route';
import api from '@/api';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...AdminRoute, ...ClientRoute, ...StaticRoute],
});

router.beforeEach(async (to, from, next) => {
  await VueStore.dispatch('routes/setLoading', true);
  const isUserLogged = VueStore.getters['user/isLogged'];

  for (const matchedRoute of to.matched) {
    if (matchedRoute.meta.isAuthForbidden === true) {
      return isUserLogged ? next('/') : next();
    }

    if (matchedRoute.meta.isAuthRequired === true) {
      if (!isUserLogged) {
        try {
          const response = await api.get('/users/profile');
          if (response.data) {
            await VueStore.dispatch('user/setUser', {
              data: response.data,
              noRedirect: true,
            });
            return handleAuthenticatedUserAccess(to, from, next);
          }
        } catch (error) {
          return next({ name: 'Login', query: { redirect: to.fullPath } });
        }
      }
      return handleAuthenticatedUserAccess(to, from, next);
    }
  }

  if (to.path === '/') {
    if (isUserLogged) {
      const initialRoute = VueStore.getters['user/initialRouteName'];
      const userBrand = VueStore.getters['user/brand'];

      return next({ name: initialRoute(), params: { id: userBrand } });
    } else {
      return next({ name: 'Login' });
    }
  }

  return next();
});

router.afterEach(async () => {
  await VueStore.dispatch('routes/setLoading', false);
});

export default router;
