import { startOfMonth, addMonths, isAfter, format, parse } from 'date-fns';
export const getMonthsListBetweenDates = (startDate, endDate) => {
    const months = [];
    let currentDate = startOfMonth(startDate);
    const finalDate = startOfMonth(endDate);
    while (!isAfter(currentDate, finalDate)) {
        months.push(format(currentDate, 'MMMM').toLowerCase());
        currentDate = addMonths(currentDate, 1);
    }
    return months;
};
export const getMonthIndex = (monthName) => {
    const date = parse(monthName, 'MMMM', new Date());
    return date.getMonth() + 1;
};
