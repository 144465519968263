import api from '@/api/v2';
export const createFileUpload = async (dto) => {
    const { data } = await api.post('/files', dto);
    return data;
};
export const confirmFileUpload = async (fileId) => {
    const { data } = await api.post(`/files/confirm/${fileId}`, {});
    return data;
};
export const uploadFileToS3 = async (fileData, uploadHandle) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(uploadHandle.fields)) {
        formData.append(key, value);
    }
    formData.append('file', fileData);
    const response = await fetch(uploadHandle.url, {
        method: 'POST',
        body: formData,
    });
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return {
        url: `${process.env.VUE_APP_CDN_V2_URL}${uploadHandle.fields.key}`,
        key: uploadHandle.fields.key,
        id: uploadHandle.fileId,
    };
};
