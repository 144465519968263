<template>
  <split-layout :left-width="80" :right-width="20" right-fluid>
    <template #leftSide>
      <offer-details-header />
      <offer-title-description
        class="mb-4"
        :title="offer.title"
        :description="offer.description"
      />
      <offer-costs
        :key="`${offer.id}-list`"
        :highlighted-object-id="highlightedObjectId"
        @row-hover-start="highlightedRow = $event"
        @row-hover-end="highlightedRow = null"
      />
      <offer-reservations />
      <offer-contracts />
      <custom-form-preview
        v-if="offer.components"
        :components="offer.components"
        :values="offer.values"
        value-class-name="font-thin text-xs mt-1 mb-4"
        label-class-name="text-xs font-semibold"
        class="mb-4 mt-6"
      />
    </template>
    <template #rightSide>
      <offer-objects-map
        :offer-id="offer.id"
        :active-marker-id="highlightedRow ? highlightedRow.id : null"
        @highlight-object="highlightedObjectId = $event"
        @reset-highlight="highlightedObjectId = null"
      />
    </template>
  </split-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VueStore from '@/store';
import { OFFER_STATUS_MAP } from '@/constants/offer';
import OfferObjectsMap from '@/views/Brand/Applications/Components/Offers/OfferObjectsMap.vue';
import OfferTitleDescription from '@/views/Brand/Applications/Components/Offers/OfferTitleDescription.vue';
import OfferCosts from '@/views/Brand/Applications/Components/Offers/Costs/OfferCosts.vue';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import { getObjectsList } from '@/api/requests/objects';

import { CONTROLLER_DIRECTION } from '@/store/modules/ui';
import SplitLayout from '@/layouts/SplitLayout.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { OfferDetailsHeader } from '@/views/Brand/Applications/Offer/Details/components/offer-details-header';
import OfferReservations from '@/views/Brand/Applications/Components/Offers/Reservations/OfferReservations.vue';
import OfferContracts from '@/views/Brand/Applications/Components/Offers/Contracts/OfferContracts.vue';

export default defineComponent({
  name: 'OfferDetails',
  components: {
    OfferContracts,
    OfferReservations,
    SplitLayout,
    OfferCosts,
    OfferTitleDescription,
    OfferObjectsMap,
    CustomFormPreview,
    OfferDetailsHeader,
  },
  data() {
    return {
      loading: false,
      lastRoute: { name: 'Offers' },
      OFFER_STATUS_MAP,
      objectRelationsCount: 0,
      CONTROLLER_DIRECTION,
      objectCardsUpdateKey: 0,
    };
  },
  computed: {
    ...mapState('offer', {
      offer: 'currentOffer',
    }),
    ...mapGetters('offer', ['nextOffer', 'prevOffer']),
    offerHeader() {
      return `${this.$t('add-brand-offer-view.offer').toUpperCase()}: ${
        this.offer.internalNumber
      }`;
    },
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch('offer/fetchOffer', to.params.offerId);

    next((vm) => {
      if (from.path !== '/') vm.lastRoute = from;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await VueStore.dispatch('offer/fetchOffer', to.params.offerId);

    next();
  },
  async beforeRouteLeave(to, from, next) {
    await VueStore.dispatch('attachment/clearAttachmentList');
    next();
  },
  watch: {
    offer: {
      async handler(value) {
        if (value) await this.checkObjectRelationCount();
      },
      immediate: true,
    },
  },
  methods: {
    async checkObjectRelationCount() {
      this.loading = true;

      try {
        const { pagination } = await getObjectsList({
          offerId: this.offer.id,
          limit: 1,
        });

        this.objectRelationsCount = pagination.total;
      } finally {
        this.loading = false;
      }
    },
  },
  setup() {
    const highlightedRow = ref(null);
    const highlightedObjectId = ref(null);

    return { highlightedRow, highlightedObjectId };
  },
});
</script>
