import { ref } from '@vue/composition-api';
export var LOCAL_STORAGE_KEY;
(function (LOCAL_STORAGE_KEY) {
    LOCAL_STORAGE_KEY["OBJEKTO"] = "objekto";
    LOCAL_STORAGE_KEY["CLIENT_ID"] = "client-id";
    LOCAL_STORAGE_KEY["WAS_SURVEY_BANNER_CLOSED"] = "was-survey-banner-closed";
})(LOCAL_STORAGE_KEY || (LOCAL_STORAGE_KEY = {}));
const readValue = (key) => {
    const localStorageItem = localStorage.getItem(key);
    if (!localStorageItem) {
        return null;
    }
    try {
        return JSON.parse(localStorageItem);
    }
    catch {
        return localStorageItem;
    }
};
export const useLocalStorage = (key) => {
    const storedValue = ref(readValue(key));
    const setStoredValue = (value) => {
        localStorage.setItem(key, value);
        storedValue.value = readValue(key);
    };
    return { storedValue, setStoredValue };
};
