import { addReportMessage, deleteReportMessage, updateReportMessage, } from '@/api/requests/reports';
import { computed, defineComponent, onMounted, ref, toRefs, watch, } from '@vue/composition-api';
import ChatUserArea from '@/views/Brand/Applications/Report/Chat/ChatUserArea.vue';
import { Btn, BtnTheme } from '@/components/Button';
import { IconSize } from '@/components/Icon';
import { WysiwygInput, WysiwygInputHeight } from '@/components/WysiwygInput';
import clonedeep from 'lodash.clonedeep';
import { createMentionUserFn } from '@/views/Brand/Applications/Report/_utils/mention-user';
import { MessageResources } from '@/views/Brand/Applications/Report/_components/MessageResources';
import { useReportResources } from '@/views/Brand/Applications/Report/_composables/use-report-resources';
import { MessageResourcesDisplay } from '@/views/Brand/Applications/Report/_components/MessageResourcesDisplay';
import { usePlaceResourcesStore } from '@/store/place-resources';
import { useObjectResourcesStore } from '@/store/object-resources';
import { mapId } from '@/util/common';
import { useMessageFiles } from '@/views/Brand/Applications/Report/_composables/use-message-files';
import { isMessageDataEmpty } from '@/views/Brand/Applications/Report/_utils/message';
import { useAuthUser } from '@/composables/use-auth-user';
import { updateOffer } from '@/api/requests/offers';
import { mdiCheck, mdiDotsVertical } from '@mdi/js';
import ChatMessageEditButtons from '@/views/Brand/Applications/Report/Chat/ChatMessage/_components/ChatMessageEditButtons.vue';
import { useMobileView } from '@/composables';
import { ActionRequiredInfo } from '@/views/Brand/Applications/Report/Details/_components/action-required-info';
import { ActionRequiredUser } from '@/views/Brand/Applications/Report/Details/_components/action-required-user';
import { useActionRequired } from '@/views/Brand/Applications/Report/_composables/use-action-required';
import ActionRequiredUsersSelect from '@/views/Brand/Applications/Report/Details/_components/action-required-users-select/ActionRequiredUsersSelect.vue';
import { BTN_THEMES } from '@/store/modules/ui';
import { ActionRequiredResponse } from '@/views/Brand/Applications/Report/_components/action-required-response';
import { ActionRequiredResponseInput } from '@/views/Brand/Applications/Report/_components/action-required-response-input';
import { useChat } from '@/store/_composables/use-chat';
import { useLongPress } from '@/composables/use-long-press';
import { AdditionalMessageActions } from '@/views/Brand/Applications/Report/Chat/ChatMessage/_components/additional-message-actions';
export default defineComponent({
    name: 'ChatMessage',
    components: {
        AdditionalMessageActions,
        ActionRequiredResponseInput,
        ActionRequiredResponse,
        ActionRequiredUsersSelect,
        ActionRequiredUser,
        ActionRequiredInfo,
        ChatMessageEditButtons,
        ChatUserArea,
        Btn,
        WysiwygInput,
        MessageResources,
        MessageResourcesDisplay,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
        messages: {
            type: Array,
            required: true,
        },
        report: {
            type: Object,
            required: true,
        },
        displayAuthor: Boolean,
        disableDelete: Boolean,
        hideAddResponseButton: Boolean,
    },
    setup(props, ctx) {
        const { $route, $i18n, $toast } = ctx.root;
        const { id: brandId, reportId } = $route.params;
        const { authUserId } = useAuthUser();
        const { isMobileView } = useMobileView();
        const { setScrollToMessageId } = useChat();
        const isHovered = ref(false);
        const uploadInProgress = ref(false);
        const fileTokens = ref([]);
        const showEditMessageMenu = ref(false);
        const actionRequiredElement = ref(null);
        watch(actionRequiredElement, () => ctx.emit('action-required-element', actionRequiredElement.value));
        const { requireActionUser, requireActionUserResponseMessage, isRequiredActionUser, isAuthorizedToCreateActionRequired, } = useActionRequired({
            user: props.message.requiredActionUser,
        });
        const isRequiredActionMessage = ref(Boolean(requireActionUser.value) &&
            !props.message.requiredActionResponseId);
        watch(requireActionUser, () => (isRequiredActionMessage.value = Boolean(requireActionUser.value)));
        onMounted(() => {
            if (isRequiredActionMessage.value) {
                setScrollToMessageId(props.message.id);
            }
        });
        const editedMessage = ref(clonedeep(props.message));
        const getMentionSuggestions = createMentionUserFn({
            accessToBrandId: brandId,
        });
        const { editedMessageId, setEditedMessageId, isEditing } = useReportResources();
        const { files, handleAddFile, handleRemoveFile, getFileTokens, clearFiles, responseFileTokens, uploadingFiles, } = useMessageFiles(ctx, toRefs(props).message);
        const { setExistingPlaces, resolvedPlaces, resetPlacesState } = usePlaceResourcesStore();
        const { setExistingObjects, resolvedObjects, resetObjectsState } = useObjectResourcesStore();
        watch(files, async (value) => {
            if (value) {
                uploadInProgress.value = true;
                fileTokens.value = await getFileTokens();
                uploadInProgress.value = false;
            }
        }, { deep: true, immediate: true });
        const isEditingThisMessage = computed(() => editedMessageId.value === props.message.id);
        const isMessageAuthor = computed(() => authUserId.value === props.message.author.id);
        const isMessageEmpty = computed(() => {
            return isMessageDataEmpty({
                message: computed(() => editedMessage.value.message),
                files,
                places: resolvedPlaces,
                objects: resolvedObjects,
            });
        });
        const onEditClick = () => {
            if (isEditing.value) {
                return $toast.error($i18n.t('report.already-editing'));
            }
            setExistingPlaces(editedMessage.value.places);
            // FIXME
            // @ts-ignore
            setExistingObjects(editedMessage.value.objects);
            setEditedMessageId(editedMessage.value.id);
            showEditMessageMenu.value = false;
        };
        const clearMessageData = () => {
            setEditedMessageId(null);
            clearFiles();
            resetPlacesState();
            resetObjectsState();
        };
        const onEditCancel = () => {
            clearMessageData();
            editedMessage.value = clonedeep(props.message);
            if (!props.message.requiredActionUser) {
                isRequiredActionMessage.value = false;
            }
        };
        const addResponseToActionRequired = async () => {
            const data = await addReportMessage({
                requiredActionMessageId: props.message.id,
                reportId: reportId,
                message: requireActionUserResponseMessage.value,
                fileTokens: responseFileTokens.value,
                placeIds: [],
                objectIds: [],
            });
            showEditMessageMenu.value = false;
            isRequiredActionMessage.value = false;
            ctx.emit('message-response-added', data);
        };
        const onEditSave = async () => {
            const updatedMessageData = await updateReportMessage(props.message.id, {
                message: editedMessage.value.message,
                fileTokens: fileTokens.value,
                placeIds: mapId(resolvedPlaces.value),
                objectIds: mapId(resolvedObjects.value),
                requiredActionUserId: requireActionUser.value?.id,
            });
            clearMessageData();
            ctx.emit('message-updated', updatedMessageData);
        };
        const onMessageDelete = async () => {
            await deleteReportMessage(props.message.id);
            if (props.message.offer) {
                await updateOffer(props.message.offer.id, { reportId: null });
            }
            ctx.emit('message-deleted', props.message.id);
            showEditMessageMenu.value = false;
        };
        const onRequiredActionDelete = async () => {
            requireActionUser.value = null;
            const updatedMessageData = await updateReportMessage(props.message.id, {
                message: editedMessage.value.message,
                fileTokens: fileTokens.value,
                placeIds: mapId(resolvedPlaces.value),
                objectIds: mapId(resolvedObjects.value),
                requiredActionUserId: null,
            });
            clearMessageData();
            ctx.emit('message-updated', updatedMessageData);
            showEditMessageMenu.value = false;
        };
        const handleCreateActionRequired = (handler) => {
            if (props.message.requiredActionResponseId) {
                return ctx.root.$toast.error(ctx.root.$t('report.action-required-remark-error'));
            }
            handler();
        };
        const onDesktopRequiredActionCreate = () => handleCreateActionRequired(() => {
            isRequiredActionMessage.value = true;
            onEditClick();
        });
        const onMobileRequiredActionCreate = () => handleCreateActionRequired(() => {
            ctx.emit('mobile-required-message-edit', props.message);
            showEditMessageMenu.value = false;
        });
        const onRequiredActionCreate = () => isMobileView.value
            ? onMobileRequiredActionCreate()
            : onDesktopRequiredActionCreate();
        const resolveMessageStyle = computed(() => {
            switch (true) {
                case isRequiredActionMessage.value:
                    return 'required-action-message';
                case isEditingThisMessage.value:
                    return 'edit-message';
                default:
                    return 'default-message';
            }
        });
        const onLongPressHandlers = useLongPress(() => {
            if (isMessageAuthor.value) {
                showEditMessageMenu.value = true;
            }
        });
        return {
            BTN_THEMES,
            mdiDotsVertical,
            mdiCheck,
            IconSize,
            onLongPressHandlers,
            files,
            isMobileView,
            BtnTheme,
            isHovered,
            isEditing,
            responseFileTokens,
            resolveMessageStyle,
            isEditingThisMessage,
            isMessageAuthor,
            editedMessage,
            getMentionSuggestions,
            isRequiredActionUser,
            isRequiredActionMessage,
            requireActionUserResponseMessage,
            isAuthorizedToCreateActionRequired,
            uploadingFiles,
            uploadInProgress,
            isMessageEmpty,
            WysiwygInputHeight,
            showEditMessageMenu,
            requireActionUser,
            actionRequiredElement,
            addResponseToActionRequired,
            clearMessageData,
            onEditClick,
            onEditCancel,
            onEditSave,
            onMessageDelete,
            onRequiredActionDelete,
            onRequiredActionCreate,
            handleAddFile,
            handleRemoveFile,
        };
    },
});
