import { defineComponent } from '@vue/composition-api';
import Btn from '@/components/Button/Button.vue';
import { BTN_THEMES } from '@/store/modules/ui';
import { Icon } from '@/components/Icon';
import CloseSvg from '@/assets/icons/close-mini.svg';
import { useCheckIfSurveysWereClosed } from '@/composables/use-check-if-surveys-were-closed';
export default defineComponent({
    name: 'SurveyBanner',
    props: {
        show: Boolean,
        isSidebarVisible: Boolean,
    },
    components: {
        Btn,
        Icon,
    },
    setup(_, ctx) {
        const { setSurveysClosed } = useCheckIfSurveysWereClosed();
        const takeSurvey = (e) => {
            ctx.emit('open', e);
        };
        const handleClose = (e) => {
            ctx.emit('close', e);
            setSurveysClosed(true);
        };
        return { setSurveysClosed, BTN_THEMES, CloseSvg, takeSurvey, handleClose };
    },
});
