import { defineComponent } from '@vue/composition-api';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { DropdownPosition, } from '@/components/Inputs/SelectInput/_components/SelectDropdown/select-dropdown.constants';
import SelectOption from '@/components/Inputs/SelectInput/_components/SelectDropdown/SelectOption.vue';
export default defineComponent({
    components: {
        SelectOption,
        InfiniteData,
    },
    props: {
        id: String,
        focusedOptionIndex: Number,
        isOptionSelected: Function,
        optionLabelName: {
            type: String,
            default: 'label',
        },
        options: {
            type: Array,
            default: () => [],
        },
        url: String,
        query: {
            type: Object,
            default: () => ({}),
        },
        disableItem: Function,
        forceUpdate: {
            type: [Boolean, Number],
            default: false,
        },
        position: {
            type: String,
            default: DropdownPosition.BOTTOM,
        },
        optionsDimensionsClass: {
            type: String,
            default: 'option-area-dimensions',
        },
        apiV2: {
            type: Boolean,
            default: false,
        },
    },
});
