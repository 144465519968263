<template>
  <component
    :is="tag"
    :class="buttonClass"
    :disabled="disabled || isLoading"
    :type="type"
    :target="target"
    :to="to"
    class="button focus:outline-none"
    @click="onClick"
  >
    <IconSpinner
      v-if="isLoading"
      class="stroke-current absolute"
      :width="iconSize"
      :height="iconSize"
    />
    <slot v-else></slot>
    <portal v-if="modal" to="modals">
      <modal :show="modal" @update:show="$emit('update:modal', false)">
        <slot name="modal" />
      </modal>
    </portal>
  </component>
</template>

<script>
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import Modal from '@/components/Modal/Modal.vue';
import { BTN_THEMES } from '@/store/modules/ui';

export default {
  name: 'Button',
  components: {
    IconSpinner,
    Modal,
  },
  props: {
    theme: {
      type: String,
      default: BTN_THEMES.PRIMARY,
      validate: (value) => Object.values(BTN_THEMES).includes(value),
    },
    size: {
      type: String,
      default: 'md',
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    target: String,
  },
  computed: {
    iconSize() {
      switch (this.size) {
        case 'sm':
          return 20;
        default:
          return 25;
      }
    },
    tag() {
      return this.to ? 'router-link' : 'button';
    },
    buttonClass() {
      return [
        this.theme,
        this.size,
        this.rounded ? 'rounded-full' : 'rounded',
        {
          disabled: this.disabled,
          compact: this.compact,
          'border-primary': !this.borderless,
        },
      ];
    },
  },
  methods: {
    onClick() {
      !this.disabled && this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  @apply outline-none cursor-pointer border-solid shadow-none leading-snug font-semibold inline-flex items-center justify-center overflow-hidden transition-all ease-linear duration-100;
  &:focus {
    @apply outline-none shadow-lg;
  }
}

.button:active {
  /* style for active state */
}

.primary {
  @apply text-white bg-primary border;
  &:hover {
    @apply bg-primary-hover;
  }
}

.secondary {
  @apply bg-white text-primary border;
  &:hover {
    @apply bg-gray-lighter border-primary-hover;
  }
}

.tertiary {
  @apply bg-white text-gray-darkest border border-gray-darkest uppercase font-normal;
  &:hover {
    @apply bg-gray-lighter;
  }
}

.quaternary {
  min-height: 48px;
  @apply text-white bg-primary border;
  &:hover {
    @apply bg-primary-hover;
  }
}

.yellow-light {
  @apply bg-yellow-light;
  &:hover {
    @apply bg-orange;
  }
}

.yellowish {
  @apply bg-yellow-light uppercase font-bold text-3xs px-3;
  &:hover {
    @apply bg-yellow;
  }
}

.orange {
  @apply bg-orange font-bold text-3xs text-white px-3;

  &:hover {
    @apply bg-orange bg-opacity-75;
  }
}

.text {
  @apply bg-white border-none text-gray-darkest;
}

.xxs {
  @apply h-4;
}

.xs {
  @apply h-6 text-xxs font-normal;
}

.xs-bold {
  @apply h-6 text-xxs font-bold;
}

.sm {
  @apply h-8 py-1.5 text-sm;
}

.md {
  @apply h-10 p-2 text-base;
}

.lg {
  @apply px-5 py-3 text-base;
}

.compact {
  @apply text-sm p-0.5 leading-4;
}

.button {
  &:disabled {
    @apply disabled-element;
  }
}
</style>
