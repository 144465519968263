import { createNamespacedHelpers } from 'vuex-composition-helpers';
const { useState, useActions } = createNamespacedHelpers('object');
export const useObjects = () => {
    const { objectType, details } = useState(['objectType', 'details']);
    const { fetchObjectTypeDetails, fetchObjectDetails } = useActions([
        'fetchObjectTypeDetails',
        'fetchObjectDetails',
        'fetchAttachments',
    ]);
    return {
        objectType,
        fetchObjectTypeDetails,
        fetchObjectDetails,
        object: details,
    };
};
