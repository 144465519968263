export const formatCurrency = (amount) => {
    if (!amount)
        return '0.00 zł';
    const parsedAmount = typeof amount === 'string' ? Number.parseFloat(amount) : amount;
    if (Number.isNaN(parsedAmount))
        return '0.00 zł';
    const formattedAmount = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(parsedAmount);
    return `${formattedAmount} zł`;
};
