import { defineComponent, ref } from '@vue/composition-api';
import FolderSvg from '@/assets/icons/folder-rounded.svg';
import { Icon, IconSize } from '@/components/Icon';
export default defineComponent({
    components: { Icon },
    props: {
        disabled: Boolean,
        dropAreaClass: String,
        allowClick: Boolean,
    },
    setup(props, { emit }) {
        const isDragging = ref(false);
        const dropArea = ref(null);
        const fileInput = ref(null);
        const handleDrop = (e) => {
            e.preventDefault();
            isDragging.value = false;
            if (e.dataTransfer && e.dataTransfer.files.length > 0) {
                const payload = {
                    target: {
                        files: e.dataTransfer.files,
                    },
                };
                emit('add-file', payload);
            }
            else {
                fileInput.value?.click();
            }
        };
        const handleDragLeave = (e) => {
            if (dropArea.value && dropArea.value.contains(e.relatedTarget)) {
                return;
            }
            isDragging.value = false;
        };
        const handleClick = () => {
            if (fileInput.value && props.allowClick) {
                fileInput.value.click();
            }
        };
        const handleChange = (e) => {
            const target = e.target;
            if (target.files) {
                const payload = {
                    target: {
                        files: target.files,
                    },
                };
                emit('add-file', payload);
            }
        };
        return {
            handleDragLeave,
            isDragging,
            handleDrop,
            fileInput,
            dropArea,
            FolderSvg,
            IconSize,
            handleClick,
            handleChange,
        };
    },
});
