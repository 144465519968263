<template>
  <header class="nav-bar">
    <Surveys v-if="!wereSurveysClosed" />
    <preview-mode-bar :sidebar="sidebar" />
    <div v-if="mobileView" class="nav-inner">
      <div class="h-full items-center flex">
        <router-link v-if="allowClientPreview" :to="clientRoute" class="z-10">
          <icon :icon="mdiChevronLeft" class="text-gray -mr-3" />
        </router-link>
        <img
          v-if="currentBrand"
          class="brand-logo"
          :src="currentBrand.logoUrl || currentBrand.cdnLogoUrl"
          :alt="`${currentBrand.name}`"
        />
      </div>
      <div class="flex items-center">
        <notifications class="mr-2" />
        <tooltip :label="$t('navbar-brand.map')">
          <btn theme="none" :to="{ name: 'Map' }">
            <icon :icon="mdiMapOutline" class="fill-gray-darkest" />
          </btn>
        </tooltip>
        <tooltip v-if="hasAdminRights" :label="$t('navbar-brand.settings')">
          <btn theme="none" :to="{ name: 'Users' }">
            <icon :icon="mdiCogOutline" class="fill-gray-darkest" />
          </btn>
        </tooltip>
        <account-info />
        <locale-switcher />
        <toggler
          v-model="sideMenu.isOpen"
          class="z-60 ml-1.5"
          :icon-size="IconSize.XL"
        />
      </div>
      <brand-mobile-side-menu
        :items="navItems"
        :is-active="isItemActive"
        :active-category="activeIndex"
        :search-value="searchValue"
        @search="onInput"
      />
    </div>
    <div v-else class="flex">
      <brand-logo
        :back-icon="backIcon"
        :back-route="backRoute"
        :light-background="lightBackground"
      />
      <div class="flex flex-col w-full">
        <div class="links-area">
          <nav-items
            :items="navItems"
            :is-active="isItemActive"
            locale-view="navbar-brand"
          />

          <div class="flex items-center pr-6">
            <notifications class="mr-2" />
            <tooltip :label="$t('navbar-brand.map')">
              <btn theme="none" :to="{ name: 'Map' }">
                <icon :icon="mdiMapOutline" class="fill-gray-darkest" />
              </btn>
            </tooltip>
            <tooltip v-if="hasAdminRights" :label="$t('navbar-brand.settings')">
              <btn theme="none" :to="{ name: 'Users' }">
                <icon :icon="mdiCogOutline" class="fill-gray-darkest" />
              </btn>
            </tooltip>
            <account-info />
            <locale-switcher />
          </div>
        </div>
      </div>
    </div>
    <nav-items
      v-if="!mobileView && isSettingsOpened"
      :is-active="isItemActive"
      :items="subNavItems"
      subnav
      locale-view="navbar-brand"
    />
  </header>
</template>

<script>
import LocaleSwitcher from '@/layouts/Components/LocaleSwitcher';
import AccountInfo from '@/layouts/Components/AccountInfo';
import NavItems from '@/layouts/Components/NavItems.vue';
import Notifications from '@/layouts/_components/Notifications/Notifications.vue';
import Toggler from '@/layouts/Components/Toggler.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ADMIN_TYPE } from '@/store/modules/user';
import { FILTER_PARAMS } from '@/constants/filters';
import BrandLogo from '@/layouts/BrandLayout/Components/BrandLogo';
import { mdiMapOutline, mdiCogOutline } from '@mdi/js';
import { mdiMagnify } from '@mdi/js';
import { IconSize } from '@/components/Icon';
import { mdiChevronLeft } from '@mdi/js';
import { useUi } from '@/composables';
import { PortalTarget } from '@/constants/portal';
import BrandMobileSideMenu from '@/layouts/BrandLayout/Components/BrandMobileSideMenu';
import { PreviewModeBar } from '@/layouts/_components/PreviewModeBar';
import Surveys from '@/layouts/_components/Surveys/Surveys.vue';
import { useCheckIfSurveysWereClosed } from '@/composables/use-check-if-surveys-were-closed';

export default {
  components: {
    BrandMobileSideMenu,
    LocaleSwitcher,
    AccountInfo,
    NavItems,
    Notifications,
    Toggler,
    Tooltip,
    Btn,
    Icon,
    BrandLogo,
    PreviewModeBar,
    Surveys,
  },
  props: {
    sidebar: Boolean,
    backIcon: Boolean,
    backRoute: Object,
    lightBackground: Boolean,
  },
  data() {
    return {
      showMenu: false,
      height: 0,
      mdiMapOutline,
      mdiCogOutline,
      mdiMagnify,
    };
  },
  computed: {
    ...mapState('user', ['type']),
    ...mapGetters('user', [
      'hasAdminRights',
      'isPlaceAdmin',
      'hasRights',
      'role',
    ]),
    ...mapGetters('routes', ['routeFilters']),
    ...mapState('client', {
      client: 'details',
    }),
    ...mapState('brand', {
      currentBrand: 'details',
    }),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    searchValue() {
      return this.routeFilters(this.$route)?.[FILTER_PARAMS.SEARCH] ?? '';
    },
    activeIndex() {
      return this.navItems.findIndex((item) =>
        this.isItemActive(item, this.$route),
      );
    },
    allowClientPreview() {
      const isObjektoAdmin = this.hasAdminRights && this.type === ADMIN_TYPE;

      return isObjektoAdmin;
    },
    isSettingsOpened() {
      return this.$route.matched.some(({ name }) => name === 'Settings');
    },
    subNavItems() {
      return [
        { label: 'users', to: 'Users' },
        { label: 'structures', to: 'Structures' },
        { label: 'categories', to: 'Categories' },
        { label: 'forms', to: 'Forms' },
        { label: 'theme', to: 'Theme' },
      ];
    },
    navItems() {
      const items = [
        {
          label: 'reports',
          to: 'Reports',
        },
        {
          label: 'offers',
          to: 'Offers',
        },
        { label: 'objects', to: 'ObjectTypes' },
        { label: 'places', to: 'Places' },
      ];

      // OBJ-2088
      // if (this.isPlaceAdmin) {
      //   items.unshift({ label: 'start', to: 'PlaceDashboard' });
      // }

      return items;
    },
    clientRoute() {
      return this.client
        ? { name: 'ClientDetails', params: { id: this.client.id } }
        : { name: 'Clients' };
    },
  },
  watch: {
    mobileView(active) {
      this.$nextTick(this.setHeight);

      if (!active) {
        this.showMenu = false;
      }
    },
    isSettingsOpened() {
      this.$nextTick(this.setHeight);
    },
    activeIndex() {
      this.$nextTick(this.setHeight);
    },
  },
  mounted() {
    this.setHeight();
  },
  methods: {
    ...mapActions('routes', ['updateFilter']),
    ...mapActions('ui', ['setOffset']),
    onInput(val) {
      this.updateFilter({
        route: this.$route,
        parameter: FILTER_PARAMS.SEARCH,
        value: val,
      });
    },
    isRouteMatched(to, route) {
      return route.name === to || route.meta.matchName === to;
    },
    isItemActive(item, route) {
      return (
        this.isRouteMatched(item.to, route) ||
        this.hasActiveSubItem(item, route)
      );
    },
    hasActiveSubItem(item, route) {
      return (
        item.subnav &&
        item.subnav.findIndex((subItem) =>
          this.isRouteMatched(subItem.to, route),
        ) !== -1
      );
    },
    setHeight() {
      this.height = this.$el.getBoundingClientRect().height;

      this.setOffset({
        component: 'navBarBrand',
        value: this.height,
      });
    },
  },
  setup() {
    const { sideMenu } = useUi();

    const { wereSurveysClosed } = useCheckIfSurveysWereClosed();
    return {
      IconSize,
      mdiChevronLeft,
      sideMenu,
      PortalTarget,
      wereSurveysClosed,
    };
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  @apply w-full z-50 fixed top-0 bg-white border-b border-gray-light;
}

.links-area {
  @apply flex flex-auto justify-between w-full bg-gray-lightest;
}

.brand-logo {
  @apply max-h-13 max-w-32;
  line-height: 54px;
}

.nav-inner {
  @apply flex justify-between items-center px-4 w-full h-16;

  @screen md {
    @apply px-10;
  }
}
</style>
