import { ROLE_ACCEPT_MAP } from '@/views/Brand/Objects/_components/Offer/accept-offer.constants';
import { dateTime } from '@/util/formatters';
import { getFullName } from '@/util/user';
import { mapGetters, mapState } from 'vuex';
import { ADMIN_TYPE } from '@/store/modules/user';
import {
  changeOfferObjectStatus,
  OfferAcceptanceStatus,
} from '@/api/v2/requests/offers';

export default {
  data() {
    return {
      modalContent: null,
      showConfirmModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('user', ['systemRole', 'type', 'id']),
    ...mapGetters('user', ['hasAdminRights']),
    canUserTakeAction() {
      const requiredRole = ROLE_ACCEPT_MAP[this.params.level];
      return (
        this.systemRole === requiredRole ||
        (this.hasAdminRights && this.type === ADMIN_TYPE)
      );
    },
    offerObjectStatus() {
      return this.params.statuses?.find(
        (objectStatus) => objectStatus.type === this.params.level,
      );
    },
    formattedDate() {
      return this.offerObjectStatus
        ? dateTime(new Date(this.offerObjectStatus?.createdAt))
        : '';
    },
    user() {
      return this.offerObjectStatus?.user
        ? getFullName(this.offerObjectStatus.user)
        : '';
    },
    canUserRevertAction() {
      return this.offerObjectStatus?.user?.id === this.id;
    },
    status() {
      return this.offerObjectStatus?.status;
    },
  },
  methods: {
    onCloseModal() {
      this.showConfirmModal = false;

      this.modalContent = null;
    },
    async changeStatus(status) {
      try {
        const updatedStatus = await changeOfferObjectStatus(
          this.params.offerId,
          this.params.objectId,
          status,
        );

        const rowNode = this.params.node;
        const rowData = rowNode.data;

        rowData.statuses = this.offerObjectStatus
          ? rowData.statuses.map((objectStatus) => {
              if (objectStatus.type === this.params.level) return updatedStatus;
              return objectStatus;
            })
          : [...(rowData.statuses ?? []), updatedStatus];

        this.params.api.refreshCells({ rowNodes: [rowNode] });
      } catch {
        this.$toast.error(this.$t(`offer-costs.decisionError`));
      }
    },
    async accept() {
      await this.changeStatus({
        status: OfferAcceptanceStatus.ACCEPTED,
        type: this.params.level,
      });
    },
    async revert() {
      if (this.canUserTakeAction) {
        await this.changeStatus({
          status: OfferAcceptanceStatus.REVERTED,
          type: this.params.level,
        });
      }
    },
    async reject() {
      await this.changeStatus({
        status: OfferAcceptanceStatus.REJECTED,
        type: this.params.level,
      });
    },
    async runActionWithModal(title, action) {
      this.showConfirmModal = true;

      this.modalContent = {
        title,
        action: async () => {
          this.loading = true;
          try {
            this.showConfirmModal = false;
            await action();
          } finally {
            this.loading = false;
          }
        },
      };
    },
  },
};
