import { defineComponent, ref } from '@vue/composition-api';
import { Icon } from '@/components/Icon';
import { mdiChevronDown } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    setup() {
        const expanded = ref(true);
        return { mdiChevronDown, expanded };
    },
});
