import { computed, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
import { ROLE_ACCEPT_MAP } from '@/views/Brand/Objects/_components/Offer/accept-offer.constants';
import { dateTime } from '@/util/formatters';
import { getFullName } from '@/util/user';
import { changeOfferObjectStatus, OfferAcceptanceStatus, } from '@/api/v2/requests/offers';
import { useOffer } from '@/composables/user-offer';
export const useObjectAccept = (ctx, { offerObject, level, objectId }) => {
    const { currentOffer } = useOffer();
    const showConfirmModal = ref(false);
    const loading = ref(false);
    const { hasRole, authUserId, isObjektoAdmin } = useAuthUser();
    const offerObjectStatus = computed(() => {
        return offerObject.statuses?.find((objectStatus) => objectStatus.type === level);
    });
    const canUserTakeAction = computed(() => {
        const requiredRole = ROLE_ACCEPT_MAP[level];
        return hasRole(requiredRole) || isObjektoAdmin.value;
    });
    const canUserRevertAction = computed(() => {
        return offerObjectStatus.value?.user?.id === authUserId.value;
    });
    const status = computed(() => {
        return offerObjectStatus.value?.status || null;
    });
    const formattedDate = computed(() => {
        return offerObjectStatus.value
            ? dateTime(new Date(offerObjectStatus.value?.createdAt))
            : '';
    });
    const user = computed(() => {
        return offerObjectStatus.value
            ? getFullName(offerObjectStatus.value.user)
            : '';
    });
    const modalContent = ref(null);
    const runActionWithModal = async (title, action) => {
        showConfirmModal.value = true;
        modalContent.value = {
            title,
            action: async () => {
                loading.value = true;
                try {
                    showConfirmModal.value = false;
                    await action();
                    ctx.emit('update-data');
                }
                finally {
                    loading.value = false;
                }
            },
        };
    };
    const changeStatus = async (status) => {
        try {
            await changeOfferObjectStatus(currentOffer.value.id, objectId, status);
        }
        catch {
            ctx.root.$toast.error(ctx.root.$t(`offer-costs.decisionError`));
        }
    };
    const revert = async () => {
        if (canUserTakeAction.value) {
            await changeStatus({
                status: OfferAcceptanceStatus.REVERTED,
                type: level,
            });
        }
    };
    const reject = async () => {
        await changeStatus({
            status: OfferAcceptanceStatus.REJECTED,
            type: level,
        });
    };
    const accept = async () => {
        await changeStatus({
            status: OfferAcceptanceStatus.ACCEPTED,
            type: level,
        });
    };
    const onCloseModal = () => {
        showConfirmModal.value = false;
        modalContent.value = null;
    };
    return {
        loading,
        status,
        user,
        showConfirmModal,
        formattedDate,
        onCloseModal,
        accept,
        reject,
        revert,
        offerObjectStatus,
        canUserTakeAction,
        canUserRevertAction,
        modalContent,
        runActionWithModal,
    };
};
