import { ResourceType } from '@/constants/resources';
export const EMPTY_OBJECT_FILTERS = {
    structure: null,
    tags: [],
    place: null,
    internalStatus: null,
};
export const EMPTY_PLACE_FILTERS = {
    structure: null,
    tags: [],
    user: null,
};
