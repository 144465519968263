import api from '@/api';
// TODO: should correspond to paths
export var AcceptanceLevel;
(function (AcceptanceLevel) {
    AcceptanceLevel["PLACE"] = "place";
    AcceptanceLevel["STRUCTURE"] = "structure";
    AcceptanceLevel["CENTRAL"] = "central";
})(AcceptanceLevel || (AcceptanceLevel = {}));
export var OfferObjectStatus;
(function (OfferObjectStatus) {
    OfferObjectStatus["REJECTED"] = "rejected";
    OfferObjectStatus["ACCEPTED"] = "accepted";
    OfferObjectStatus["REVERTED"] = "reverted";
})(OfferObjectStatus || (OfferObjectStatus = {}));
export const updateOffer = async (offerId, payload) => {
    const { data } = await api.patch(`/offers/${offerId}`, payload);
    return data;
};
export const updateOfferObjectRelation = (relationId, level, status) => {
    return api.put(`/offers/object-relations/${relationId}/${level}`, { status });
};
export const deleteOffer = async (offerId) => {
    const { data } = await api.delete(`offers/${offerId}`);
    return data;
};
