import { computed, defineComponent } from '@vue/composition-api';
import { PreviewWrapper } from '@/views/Brand/Applications/Components/Preview/PreviewWrapper';
import { getFullAddress } from '@/util/common';
export default defineComponent({
    name: 'ObjectPreview',
    components: { PreviewWrapper },
    props: {
        object: {
            type: Object,
            required: true,
        },
    },
    setup(props, ctx) {
        const image = computed(() => ({
            src: props.object.logoUrl,
            alt: ctx.root.$i18n
                .t('object.object-alt', {
                internalNumber: props.object.internalNumber,
            })
                .toString(),
        }));
        return { image, getFullAddress };
    },
});
