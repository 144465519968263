import Card from '@/components/Card/Card.vue';
import ObjectTypesSidebar from '@/views/Brand/ObjectTypes/ObjectTypesSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import EntriesCounter from '@/components/Table/EntriesCounter.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { PortalTarget } from '@/constants/portal';
import { mapQueryToApiV2 } from '@/util/query';
import { computed, defineComponent, onMounted, ref, } from '@vue/composition-api';
import { useRouteQuery } from '@/composables';
import { FILTER_DEBOUNCE } from '@/constants/filters';
import { DEFAULT_CARD_LIMIT } from '@/constants/pagination';
import ContentWrapper from '@/layouts/BrandLayout/Components/ContentWrapper.vue';
export default defineComponent({
    name: 'ObjectTypes',
    components: {
        Card,
        ObjectTypesSidebar,
        FetchData,
        IconSpinner,
        Pagination,
        Breadcrumbs,
        EntriesCounter,
        FilterToggler,
        ContentWrapper,
    },
    setup(props, ctx) {
        const objectTypesList = ref([]);
        const lastPage = ref();
        const { routeQuery, changePage, selectLimit } = useRouteQuery(ctx);
        const computedQuery = computed(() => {
            const { q, ...query } = mapQueryToApiV2(routeQuery.value);
            return {
                ...query,
                name: q,
                brandId: ctx.root.$route.params.id,
                forAuthUser: 'true',
            };
        });
        const currentPage = computed(() => {
            return computedQuery.value?.page ?? 1;
        });
        const updateList = (data) => {
            objectTypesList.value = data.nodes;
            lastPage.value = Math.ceil(data.totalCount / (computedQuery.value?.perPage || 8));
        };
        onMounted(() => {
            selectLimit(DEFAULT_CARD_LIMIT);
        });
        return {
            PortalTarget,
            FILTER_DEBOUNCE,
            objectTypesList,
            lastPage,
            currentPage,
            computedQuery,
            updateList,
            changePage,
        };
    },
});
