import { computed, defineComponent } from '@vue/composition-api';
import { useExpandDetails, useMobileView } from '@/composables';
import { useObjectStatus } from '@/composables/use-object-status';
import ObjectPreview from '@/views/Brand/Objects/ObjectPreview.vue';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import { Checkbox } from '@/components/Inputs';
import { GenericTable } from '@/components/Table/__new__/_components/TableGeneric.vue';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { TableStyle } from '@/components/Table/__new__';
import PlaceWithPopover from '@/views/_components/PlaceWithPopover.vue';
export default defineComponent({
    components: {
        ObjectPreview,
        Checkbox,
        TableDisplay: GenericTable(),
        ExpandButton,
        StatusToggler,
        PlaceWithPopover,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        isObjectSelected: {
            type: Function,
            default: () => false,
        },
    },
    setup(props, ctx) {
        const { getStatusOptions, OBJECT_STATUS_COLORS } = useObjectStatus();
        const { isMobileView } = useMobileView();
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'internalNumber',
                    label: ctx.root.$t('add-brand-report-view.no').toString(),
                    cellClass: 'font-bold pl-0',
                    sortable: true,
                },
                {
                    key: 'place',
                    label: ctx.root.$t('add-brand-report-view.place').toString(),
                    hideDefaultContent: true,
                    cellClass: 'font-bold',
                    sortable: true,
                    hideInMobile: true,
                },
                {
                    key: 'address',
                    label: ctx.root.$t('add-brand-report-view.address').toString(),
                    formatter: (address, row) => `${row.city}, ${address}`,
                    gridColumn: isMobileView ? '0.5fr' : '1fr',
                },
                {
                    key: 'internalStatus',
                    label: ctx.root.$t('object-status.status').toString(),
                    sortable: true,
                    hideDefaultContent: true,
                },
                {
                    key: 'expandButton',
                    cellClass: 'pl-0 pr-0',
                },
            ];
        });
        const { isExpanded: isObjectDetailsExpanded, toggleExpandedItems: toggleExpandObjectDetails, } = useExpandDetails((object) => object.id);
        return {
            columns,
            isObjectDetailsExpanded,
            toggleExpandObjectDetails,
            ExpandButtonStyle,
            TableStyle,
            OBJECT_STATUS_COLORS,
            getStatusOptions,
        };
    },
});
