import { SCROLLABLE_CONTAINER_ID } from '@/layouts/constants';
export const useScrollElementIntoCenter = () => {
    const scrollIntoCenter = (el) => {
        const container = document.querySelector(`#${SCROLLABLE_CONTAINER_ID}`);
        if (!container)
            return;
        const containerRect = container.getBoundingClientRect();
        const elementRect = el.getBoundingClientRect();
        const offset = elementRect.top -
            containerRect.top -
            container.clientHeight / 2 +
            el.clientHeight / 2;
        container.scrollTo({
            top: container.scrollTop + offset,
            behavior: 'smooth',
        });
    };
    return { scrollIntoCenter };
};
