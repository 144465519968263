import axios from 'axios';
import { requestInterceptor, responseErrorInterceptor } from '@/api/helpers';

export const apiV2Url = process.env.VUE_APP_API_V2_URL;

const apiV2 = axios.create({
  withCredentials: true,
  baseURL: apiV2Url,
});

apiV2.interceptors.request.use(requestInterceptor);

apiV2.interceptors.response.use((res) => res, responseErrorInterceptor);

export default apiV2;
