import api from '@/api';
import apiV2 from '@/api/v2';

export default {
  namespaced: true,
  actions: {
    addObjectTag(ctx, { objectId, tagName }) {
      return api.post(`/tags/${tagName}/objects/${objectId}`);
    },
    deleteObjectTag(ctx, { objectId, tagId }) {
      return apiV2.delete(`/object-tags/${tagId}/object/${objectId}`);
    },
    addPlaceTag(ctx, { placeId, tagName }) {
      return api.post(`/tags/${tagName}/places/${placeId}`);
    },
    deletePlaceTag(ctx, { placeId, tagId }) {
      return api.delete(`/tags/${tagId}/places/${placeId}`);
    },
    async saveTags(
      { dispatch },
      { objectId, tagsToDelete = [], tagsToSave = [] },
    ) {
      const promises = [
        ...tagsToDelete.map(({ id }) => {
          return dispatch('deleteObjectTag', {
            objectId,
            tagId: id,
          });
        }),
        ...tagsToSave.map(({ name }) => {
          return dispatch('addObjectTag', {
            objectId,
            tagName: name,
          });
        }),
      ];

      await Promise.all(promises);
    },
  },
};
