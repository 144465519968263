// @ts-ignore
import resize from 'vue-resize-directive';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { Btn, BtnTheme } from '@/components/Button';
import { calcTextWidth } from '@/util/text';
import PlaceWithPopover from '@/views/_components/PlaceWithPopover.vue';
import AllPlacesModal from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/_components/AllPlacesModal.vue';
import { PortalTarget } from '@/constants/portal';
export default defineComponent({
    name: 'ChatReportPlacesList',
    components: {
        AllPlacesModal,
        PlaceWithPopover,
        Btn,
    },
    directives: {
        resize,
    },
    props: {
        reportPlaces: {
            type: Object,
            required: true,
        },
        reportId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const listArea = ref(null);
        const displayWidth = ref(0);
        const buttonWidth = ref(0);
        const showAllPlaces = ref(false);
        const placesToDisplay = computed(() => {
            let count = 0;
            let currentWidth = 0;
            const font = listArea.value
                ? getComputedStyle(listArea.value).font
                : getComputedStyle(document.body).font;
            for (const place of props.reportPlaces.results) {
                const placeNameWidth = calcTextWidth(`${place.name}, `, font);
                if (placeNameWidth + currentWidth + buttonWidth.value + 20 >
                    displayWidth.value) {
                    break;
                }
                count += 1;
                currentWidth += placeNameWidth;
            }
            return props.reportPlaces.results.slice(0, count);
        });
        const additionalPlacesCount = computed(() => {
            return props.reportPlaces.pagination.total - placesToDisplay.value.length;
        });
        const onDisplayResize = (element) => {
            displayWidth.value = element.clientWidth;
        };
        const onButtonResize = (element) => {
            buttonWidth.value = element.clientWidth;
        };
        return {
            BtnTheme,
            listArea,
            onDisplayResize,
            onButtonResize,
            placesToDisplay,
            additionalPlacesCount,
            showAllPlaces,
            PortalTarget,
        };
    },
});
