import { computed, watch } from '@vue/composition-api';
import { getFirstReportMessage, getSingleReport, markReportAsSeen, } from '@/api/requests/reports';
import { getUsersList } from '@/api/requests/users';
import { getPlacesList } from '@/api/requests/places';
import { usePromise } from '@/composables';
import { loadAllResults } from '@/api/requests/pagination';
import { isReportMessageUnread } from '@/views/Brand/Applications/Report/_components/ReportTable/_utils';
import { getObjectsList } from '@/api/v2/requests/objects';
export const useReportChatData = (reportId, messages) => {
    const dataPromises = async () => {
        const [report, firstMessage, users, reportPlaces, reportObjects] = await Promise.all([
            getSingleReport(reportId.value),
            getFirstReportMessage(reportId.value),
            loadAllResults(getUsersList, {
                reportId: reportId.value,
            }),
            getPlacesList({ reportId: reportId.value, limit: 100 }),
            getObjectsList({
                reportId: reportId.value,
                perPage: 100,
            }),
        ]);
        return {
            report,
            firstMessage,
            users,
            reportPlaces,
            reportObjects,
        };
    };
    const { loading, error, result: data, fetchData, } = usePromise(dataPromises());
    const refreshReportData = async () => {
        messages.value = [];
        await fetchData(dataPromises());
    };
    const fetchUsers = async () => {
        if (data.value) {
            data.value.users = await loadAllResults(getUsersList, {
                reportId: reportId.value,
            });
        }
    };
    watch(data, async (value) => {
        if (!value) {
            return;
        }
        if (isReportMessageUnread(value?.report)) {
            await markReportAsSeen({ reportIds: [reportId.value] });
        }
    }, { immediate: true, deep: true });
    const incompleteReport = computed(() => !data.value?.firstMessage);
    return {
        data,
        loading,
        error,
        fetchUsers,
        refreshReportData,
        incompleteReport,
    };
};
