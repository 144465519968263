import { defineComponent, } from '@vue/composition-api/dist/vue-composition-api';
import { computed } from '@vue/composition-api';
import { ImageShape } from '@/components/ImageView/image.constants';
import { IMAGE_PLACEHOLDER_URL, AVATAR_PLACEHOLDER_URL, } from '@/constants/common';
export default defineComponent({
    name: 'ImageView',
    props: {
        src: {
            type: [String, null],
            default: null,
        },
        alt: {
            type: String,
            required: true,
        },
        coverImg: Boolean,
        size: {
            type: String,
        },
        withoutPadding: Boolean,
        shape: {
            type: String,
            default: ImageShape.SQUARED,
        },
        hideSizeInSrc: Boolean,
        isAvatar: Boolean,
    },
    setup(props) {
        const imageSrc = computed(() => {
            if (!props.src) {
                return props.isAvatar ? AVATAR_PLACEHOLDER_URL : IMAGE_PLACEHOLDER_URL;
            }
            if (props.size && !props.hideSizeInSrc) {
                return `${props.src}-${props.size}`;
            }
            return props.src;
        });
        return {
            imageSrc,
            ImageShape,
        };
    },
});
