import { computed } from '@vue/composition-api';
import { getMonthIndex, getMonthsListBetweenDates } from '@/util/list-months';
const floatingTextFiltersParams = {
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: 'FloatingTextFilter',
    suppressFloatingFilterButton: true,
    suppressHeaderMenuButton: true,
};
const romanMonths = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
    'XI',
    'XII',
];
export const useCommonColumns = (ctx, { offer, contract, useOfferObjectDetails }) => {
    const monthsColumns = computed(() => {
        const monthsInOffer = getMonthsListBetweenDates(new Date(offer.startDate), new Date(offer.endDate));
        return monthsInOffer.map((month) => ({
            field: 'availability',
            colId: month,
            sortable: false,
            width: 140,
            headerName: romanMonths[getMonthIndex(month) - 1],
            cellRenderer: 'AvailabilityCell',
            cellRendererParams: (params) => ({
                availability: params?.data?.availability?.find((item) => item.month === getMonthIndex(month)),
                contract,
            }),
        }));
    });
    const commonColumns = computed(() => {
        return [
            {
                field: 'internalNumber',
                width: 90,
                headerName: ctx.root.$t('offer-costs.number').toString(),
                pinned: 'left',
                suppressMovable: true,
                cellRenderer: 'ObjectNumberCell',
                cellRendererParams: (params) => {
                    if (params?.node?.rowPinned)
                        return {};
                    return {
                        offerId: useOfferObjectDetails ? offer.id : null,
                        id: params?.data?.id,
                        internalNumber: params?.data?.internalNumber,
                        objectTypeId: params?.data?.objectType?.id,
                    };
                },
                floatingFilterComponentParams: {
                    compare: 'startsWith',
                },
                ...floatingTextFiltersParams,
            },
            {
                field: 'width',
                headerName: ctx.root.$t('offer-costs.width').toString(),
                width: 90,
                valueGetter: (params) => {
                    if (params?.node?.rowPinned)
                        return '';
                    return `${params?.data?.width ?? '-'}`;
                },
                floatingFilterComponentParams: {
                    compare: 'startsWith',
                },
                ...floatingTextFiltersParams,
            },
            {
                field: 'height',
                headerName: ctx.root.$t('offer-costs.height').toString(),
                width: 90,
                floatingFilterComponentParams: {
                    compare: 'startsWith',
                },
                valueGetter: (params) => {
                    if (params?.node?.rowPinned)
                        return '';
                    return `${params?.data?.height ?? '-'}`;
                },
                ...floatingTextFiltersParams,
            },
            {
                field: 'city',
                minWidth: 160,
                flex: 2,
                headerName: ctx.root.$t('offer-costs.city').toString(),
                ...floatingTextFiltersParams,
            },
            {
                field: 'address',
                minWidth: 160,
                flex: 2,
                headerName: ctx.root.$t('offer-costs.address').toString(),
                ...floatingTextFiltersParams,
            },
            {
                field: 'place',
                headerName: ctx.root.$t('offer-costs.place').toString(),
                minWidth: 160,
                flex: 1,
                valueGetter: (params) => params?.data?.place?.name,
                ...floatingTextFiltersParams,
            },
            ...monthsColumns.value,
        ];
    });
    return { commonColumns };
};
