import axios from 'axios';
import { requestInterceptor, responseErrorInterceptor } from '@/api/helpers';

const api = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use(requestInterceptor);

api.interceptors.response.use((res) => res, responseErrorInterceptor);

export const apiUrl = process.env.VUE_APP_API_URL;

export default api;
