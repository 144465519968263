import { computed } from '@vue/composition-api';
import { date } from '@/util/formatters';
import { OfferStatus } from '@/api/v2/requests/offers';
export const useOfferColumns = (ctx) => {
    const columns = computed(() => {
        return [
            {
                field: 'internalNumber',
                headerName: '#',
                sortable: true,
                maxWidth: 80,
                minWidth: 0,
                flex: 0,
                pinned: 'left',
            },
            {
                field: 'title',
                headerName: ctx.root.$t('brand-offers.title').toString(),
                flex: 1,
                minWidth: 150,
                sortable: true,
                filter: true,
                floatingFilter: true,
                floatingFilterComponent: 'FloatingTextFilter',
                suppressFloatingFilterButton: true,
            },
            {
                field: 'status',
                headerName: ctx.root.$t('brand-offers.status').toString(),
                width: 200,
                cellRenderer: 'OfferStatusCell',
                cellRendererParams: (params) => ({
                    status: params?.data.status,
                }),
                filter: true,
                floatingFilter: true,
                floatingFilterComponent: 'FloatingSelectFilter',
                floatingFilterComponentParams: () => ({
                    options: Object.values(OfferStatus).map((status) => ({
                        value: status,
                        label: ctx.root.$t(`offer-status.${status}`).toString(),
                    })),
                }),
                suppressFloatingFilterButton: true,
            },
            {
                field: 'createdAt',
                headerName: ctx.root.$t('brand-offers.created').toString(),
                sortable: true,
                cellRenderer: (params) => date(params?.data.createdAt),
            },
        ];
    });
    return { columns };
};
