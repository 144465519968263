import {
  getCategory,
  getStatus,
  getObjectTypes,
  getPlace,
  getPlaces,
  getStructure,
  getUser,
  getTags,
  getSortBy,
} from '@/util/filter-getters';
import { OBJECT_STATUS_MAP } from '@/constants/object';

export const FILTER_DEBOUNCE = 500;

export const FILTER_PARAMS = {
  SEARCH: 'q',
  SORT_BY: 'sortBy',
  STRUCTURE: 'structureId',
  USER: 'userId',
  TAGS: 'tags',
  CREATOR: 'creatorId',
  SUPERVISOR: 'supervisorId',
  PLACES: 'placeIds',
  STATUS: 'status',
  CATEGORY: 'categoryId',
  INTERNAL_STATUS: 'internalStatus',
  PLACE: 'placeId',
  OBJECT_TYPES: 'objectTypeIds',
  IS_IMPORTANT: 'isImportant',
};

export const SORT = {
  CREATED_AT_ASC: '+createdAt',
  CREATED_AT_DESC: '-createdAt',
  NAME_ASC: '+name',
  NAME_DESC: '-name',
  LAST_ACTION_AT_ASC: '+lastActionAt',
  LAST_ACTION_AT_DESC: '-lastActionAt',
  DESCRIPTION_ASC: '+description',
  DESCRIPTION_DESC: '-description',
};

export const SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
  '+': 'asc',
  '-': 'desc',
};

export const DEFAULT_QUERY_FORMATTERS = {
  [FILTER_PARAMS.SORT_BY]: (sortOption) => sortOption?.value,
  [FILTER_PARAMS.STRUCTURE]: (structure) => structure?.id,
  [FILTER_PARAMS.USER]: (user) => user?.id,
  [FILTER_PARAMS.TAGS]: (tags) => (tags ? tags.map((tag) => tag.name) : []),
  [FILTER_PARAMS.CREATOR]: (creator) => creator?.id,
  [FILTER_PARAMS.SUPERVISOR]: (supervisor) => supervisor?.id,
  [FILTER_PARAMS.PLACES]: (places) =>
    places ? places.map((place) => place.id) : [],
  [FILTER_PARAMS.CATEGORY]: (category) => category?.id,
  [FILTER_PARAMS.INTERNAL_STATUS]: (status) => status?.value || '',
  [FILTER_PARAMS.OBJECT_TYPES]: (types) =>
    types ? types.map((type) => type.id) : [],
  [FILTER_PARAMS.PLACE]: (place) => place?.id,
};

export const TAG_DISPLAY_FORMATTERS = {
  [FILTER_PARAMS.STRUCTURE]: (structure) => structure.name,
  [FILTER_PARAMS.INTERNAL_STATUS]: (status) => status.label,
  [FILTER_PARAMS.TAGS]: (tags) =>
    Array.isArray(tags) ? tags.map((tag) => tag.name) : tags.name,
  [FILTER_PARAMS.PLACES]: (places) =>
    Array.isArray(places) ? places.map((place) => place.name) : places.name,
  [FILTER_PARAMS.OBJECT_TYPES]: (object) =>
    Array.isArray(object) ? object.map((place) => place.name) : object.name,
};

export const DEFAULT_VALUE_GETTERS = {
  [FILTER_PARAMS.STRUCTURE]: getStructure,
  [FILTER_PARAMS.USER]: getUser,
  [FILTER_PARAMS.CREATOR]: getUser,
  [FILTER_PARAMS.PLACES]: getPlaces,
  [FILTER_PARAMS.PLACE]: getPlace,
  [FILTER_PARAMS.CATEGORY]: getCategory,
  [FILTER_PARAMS.OBJECT_TYPES]: getObjectTypes,
  [FILTER_PARAMS.STATUS]: getStatus,
  [FILTER_PARAMS.TAGS]: getTags,
  [FILTER_PARAMS.SORT_BY]: getSortBy,
  [FILTER_PARAMS.SUPERVISOR]: getUser,
};

const DEFAULT_SORT = {
  [FILTER_PARAMS.SORT_BY]: { value: SORT.CREATED_AT_DESC },
};

const DEFAULT_REPORTS_SORT = {
  [FILTER_PARAMS.SORT_BY]: { value: SORT.LAST_ACTION_AT_DESC },
};

export const DEFAULT_ROUTE_FILTERS = {
  Objects: {
    ...DEFAULT_SORT,
    [FILTER_PARAMS.INTERNAL_STATUS]: OBJECT_STATUS_MAP.ACTIVE,
  },
  Places: DEFAULT_SORT,
  Reports: DEFAULT_REPORTS_SORT,
  Users: DEFAULT_SORT,
  Offers: DEFAULT_SORT,
  Forms: DEFAULT_SORT,
  Admins: DEFAULT_SORT,
};
