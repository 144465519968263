import apiV2 from '@/api/v2';
export var OfferStatus;
(function (OfferStatus) {
    OfferStatus["ACTIVE"] = "active";
    OfferStatus["APPROVED"] = "approved";
    OfferStatus["REJECTED"] = "rejected";
    OfferStatus["FINISHED"] = "finished";
    OfferStatus["ARCHIVED"] = "archived";
    OfferStatus["DRAFT"] = "draft";
    OfferStatus["CONTRACT"] = "contract";
})(OfferStatus || (OfferStatus = {}));
export var OfferAcceptanceStatus;
(function (OfferAcceptanceStatus) {
    OfferAcceptanceStatus["ACCEPTED"] = "accepted";
    OfferAcceptanceStatus["REJECTED"] = "rejected";
    OfferAcceptanceStatus["REVERTED"] = "reverted";
})(OfferAcceptanceStatus || (OfferAcceptanceStatus = {}));
export var OfferAcceptanceType;
(function (OfferAcceptanceType) {
    OfferAcceptanceType["CENTRAL"] = "central";
    OfferAcceptanceType["STRUCTURE"] = "structure";
    OfferAcceptanceType["PLACE"] = "place";
})(OfferAcceptanceType || (OfferAcceptanceType = {}));
export var OfferObjectAvailability;
(function (OfferObjectAvailability) {
    OfferObjectAvailability["IN_OFFER"] = "in-offer";
    OfferObjectAvailability["IN_CONTRACT"] = "in-contract";
    OfferObjectAvailability["AVAILAIBLE"] = "available";
})(OfferObjectAvailability || (OfferObjectAvailability = {}));
export const getOfferList = async (params) => {
    const { data } = await apiV2.get('/offers', {
        params,
    });
    return data;
};
export const changeOfferObjectStatus = async (offerId, objectId, params) => {
    const { data } = await apiV2.post(`/offers/${offerId}/objects/${objectId}/status`, params);
    return data;
};
export const getOfferContract = async (offerId) => {
    const { data } = await apiV2.get('/contracts', {
        params: {
            offerId,
            type: 'client',
        },
    });
    if (data.totalCount === 0)
        return null;
    const [contract] = data.nodes;
    return contract;
};
export const getPreviousOffer = async (offerId, params) => {
    const { data } = await apiV2.get(`offers/${offerId}/next`, {
        params: {
            ...params,
            sort: '-createdAt',
        },
    });
    return data;
};
export const getNextOffer = async (offerId, params) => {
    const { data } = await apiV2.get(`offers/${offerId}/next`, {
        params: {
            ...params,
            sort: 'createdAt',
        },
    });
    return data;
};
