<template>
  <router-link v-if="params.id" :to="link" :target="target">
    {{ params.internalNumber }}
  </router-link>
</template>

<script>
import { ClientRouteName } from '@/router/router.constants';

export default {
  computed: {
    link() {
      if (this.params.offerId)
        return {
          name: ClientRouteName.OBJECT_IN_OFFER_DETAILS,
          params: { objectId: this.params.id, offerId: this.params.offerId },
        };

      return {
        name: ClientRouteName.OBJECT_DETAILS,
        params: {
          objectId: this.params.id,
          objectTypeId: this.params.objectTypeId,
        },
      };
    },
    target() {
      return this.params.offerId ? '' : '_blank';
    },
  },
};
</script>
