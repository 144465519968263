import { STRUCTURE_ADMIN } from '@/store/modules/user';
import difference from 'lodash.difference';
import { addObjectTag, deleteObjectTag, } from '@/api/v2/requests/objects';
import { computed, ref } from '@vue/composition-api';
import { useAuthUser } from '@/composables/use-auth-user';
import { useBrand } from '@/composables/use-brand';
import { v4 as uuidv4 } from 'uuid';
export const useObjectTags = () => {
    const loadingTags = ref(false);
    const tags = ref([]);
    const tagsUploadQueue = ref([]);
    const tagsDeleteQueue = ref([]);
    const { hasRoleAccessOrHigher, isEmployee, authUserId } = useAuthUser();
    const { brand } = useBrand();
    const isAuthorizedToModifyTags = computed(() => {
        return hasRoleAccessOrHigher.value(STRUCTURE_ADMIN) || isEmployee.value;
    });
    const removeTag = async (tag, objectId) => {
        loadingTags.value = true;
        try {
            await deleteObjectTag(tag.id, objectId);
            tags.value = tags.value?.filter(({ id }) => id !== tag.id);
        }
        finally {
            loadingTags.value = false;
        }
    };
    const saveTag = async (tag, objectId, updateTags = true) => {
        loadingTags.value = true;
        try {
            const name = typeof tag === 'string' ? tag.toUpperCase() : tag.name;
            const { data } = await addObjectTag({
                name,
                brandId: brand.value.id,
                objectId,
            });
            if (updateTags) {
                tags.value = [...tags.value, data];
            }
        }
        finally {
            loadingTags.value = false;
        }
    };
    const addTagToUploadQueue = (tag) => {
        const tagToUpload = typeof tag === 'string'
            ? {
                name: tag,
                id: uuidv4(),
                createdAt: new Date(Date.now()).toLocaleString(),
                creatorId: authUserId.value,
            }
            : tag;
        if (!tagsUploadQueue.value.find(({ name }) => name == tagToUpload.name)) {
            tagsUploadQueue.value = [...tagsUploadQueue.value, tagToUpload];
        }
    };
    const addTagToDeleteQueue = (tag, uploaded = true) => {
        if (uploaded) {
            tagsDeleteQueue.value = [...tagsDeleteQueue.value, tag];
        }
        else {
            tagsUploadQueue.value = tagsUploadQueue.value.filter(({ name }) => name !== tag.name);
        }
    };
    const updateQueues = (savedTags, updatedTags) => {
        tagsUploadQueue.value = difference(updatedTags, savedTags);
        tagsDeleteQueue.value = difference(savedTags, updatedTags);
    };
    const checkDeleteTagPermission = (tag) => {
        return authUserId.value === tag.creatorId;
    };
    const createNewTag = async (tagData, objectId) => {
        const isTagAdded = tagData.options.some((option) => option.name === tagData.enteredValue) ||
            tags.value.some((tag) => tag.name === tagData.enteredValue);
        if (!isTagAdded) {
            await saveTag(tagData.enteredValue, objectId, true);
        }
    };
    return {
        tags,
        createNewTag,
        checkDeleteTagPermission,
        updateQueues,
        addTagToDeleteQueue,
        addTagToUploadQueue,
        saveTag,
        removeTag,
        isAuthorizedToModifyTags,
    };
};
