export const FORM_MODE = {
  ADD: 'add',
  EDIT: 'edit',
};

export const FORM_TYPE = {
  REPORT: 'report',
};

export const FORM_ELEMENT_TYPE = {
  CHECKBOX: 'checkbox_list',
  RADIO: 'radio_list',
  TEXT: 'test_input',
};
