<template>
  <div class="h-full w-full flex items-center">
    <status-button
      :status="$t(`offer-status.${params.status}`)"
      :color="color"
      disabled
    />
  </div>
</template>

<script>
import { OFFER_STATUS_COLORS } from '@/constants/offer';
import StatusButton from '@/components/StatusToggler/StatusButton.vue';

export default {
  components: { StatusButton },
  computed: {
    color() {
      return OFFER_STATUS_COLORS[this.params.status];
    },
  },
};
</script>
