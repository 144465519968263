import { convertDMS, link } from '@/util/formatters';
import ExpandableRowPreview from '@/components/Table/ExpandableRowPreview.vue';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import { BtnTheme } from '@/components/Btn';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: {
        ExpandableRowPreview,
        CustomFormPreview,
    },
    props: {
        objectTypeId: {
            type: String,
            default: '',
        },
        objectId: {
            type: String,
            default: '',
        },
        hideDetailsBtn: {
            type: Boolean,
            default: false,
        },
        sidebar: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const getAddress = (data) => {
            const { postalCode, city, address } = data;
            return `${address}, ${postalCode || ''} ${city}`;
        };
        const onOpenDetails = () => {
            ctx.root.$router.push({
                name: 'ObjectDetails',
                params: { objectId: props.objectId, objectTypeId: props.objectTypeId },
            });
        };
        return {
            BtnTheme,
            convertDMS,
            link,
            getAddress,
            onOpenDetails,
        };
    },
});
