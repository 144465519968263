import { convertDMS } from '@/util/formatters';
import { CustomFormPreview } from '@/views/_components/CustomForms';
import PlaceWithPopover from '@/views/_components/PlaceWithPopover.vue';
import Tag from '@/components/Tag/Tag.vue';
import StarToggle from '@/components/StarToggle/StarToggle.vue';
import { updateObjectImportant } from '@/api/requests/objects';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';
import { fetchAllItems } from '@/util/api';
import { useObjectTags } from '@/composables/use-object-tags';
import { computed, defineComponent, onMounted, } from '@vue/composition-api';
import { useObjects } from '@/composables';
import { useOffer } from '@/composables/user-offer';
export default defineComponent({
    components: {
        StarToggle,
        PlaceWithPopover,
        CustomFormPreview,
        Tag,
    },
    props: {
        object: {
            type: Object,
            required: true,
        },
        placePreview: {
            type: String,
            default: ObjectDetailsPlacePreview.POPOVER,
        },
        offerObject: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx) {
        const { fetchObjectDetails } = useObjects();
        const { currentOffer } = useOffer();
        const { tags, removeTag } = useObjectTags();
        const changeImportanceStatus = async (value) => {
            await updateObjectImportant(props.object.id, { isImportant: value });
            await fetchObjectDetails({
                id: props.object.id,
                offerId: props.offerObject ? currentOffer.value.id : undefined,
            });
        };
        const onDeleteTag = async (tag) => {
            await removeTag(tag, props.object.id);
        };
        onMounted(async () => {
            tags.value = await fetchAllItems('/object-tags', { objectId: props.object.id }, [], true);
        });
        const objectProperties = computed(() => {
            return [
                {
                    label: ctx.root.$t('object-details-view.dimensions'),
                    key: 'dimensions',
                    value: props.object.width && props.object.height
                        ? `${props.object.width} x ${props.object.height} cm`
                        : '-',
                },
                {
                    label: ctx.root.$t('object-details-view.production-dimensions'),
                    key: 'dimensions',
                    value: props.object.width && props.object.height
                        ? `${props.object.productionWidth} x ${props.object.productionHeight} cm`
                        : '-',
                },
                {
                    label: ctx.root.$t('object-details-view.light'),
                    value: ctx.root.$t(`object-details-view.${props.object.isLit ? 'yes' : 'no'}`),
                },
                {
                    label: ctx.root.$t('object-details-view.format'),
                    key: 'format',
                },
                {
                    label: ctx.root.$t('object-details-view.material'),
                    key: 'material',
                },
                {
                    label: ctx.root.$t('object-details-view.type'),
                    key: 'type',
                },
            ];
        });
        return {
            objectProperties,
            tags,
            ObjectDetailsPlacePreview,
            convertDMS,
            changeImportanceStatus,
            onDeleteTag,
        };
    },
});
