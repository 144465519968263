import { computed, defineComponent } from '@vue/composition-api';
import { ClusteredMap } from '@/components/Map';
import { MapDataType } from '@/components/Map/types';
export default defineComponent({
    components: { ClusteredMap },
    props: {
        offerId: {
            type: String,
            required: true,
        },
        activeMarkerId: {
            type: String,
            default: null,
        },
    },
    setup(props, ctx) {
        const dataSets = computed(() => {
            return [
                {
                    name: 'offer-objects',
                    url: 'objects-map',
                    query: {
                        offerId: props.offerId,
                    },
                    type: MapDataType.OBJECT,
                    fetchAll: true,
                    labelAccessor: ['internalNumber'],
                    labelFormatter: (label) => `#${label}`,
                },
                {
                    name: 'places',
                    url: 'places',
                    query: {
                        isPositionDefined: true,
                        offerId: props.offerId,
                    },
                    type: MapDataType.PLACE,
                    fetchAll: true,
                    labelAccessor: ['number'],
                    labelFormatter: (label) => `#${label}`,
                },
                {
                    name: 'hardcoded-objects',
                    url: 'objects-map',
                    query: {
                        isHardcoded: true,
                    },
                    type: MapDataType.OBJECT,
                    fetchAll: true,
                    labelAccessor: ['internalNumber'],
                    labelFormatter: (label) => `#${label}`,
                    excludeFromCentring: true,
                },
            ];
        });
        const highlightObject = (type, id) => {
            if (type === 'object')
                ctx.emit('highlight-object', id);
        };
        return { dataSets, highlightObject };
    },
});
