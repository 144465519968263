<template>
  <span v-if="price" class="flex h-full gap-1 items-center">
    <span class="flex items-center text-sm font-semibold">
      {{ formatCurrency(price) }}
    </span>
    <span class="flex items-center text-2xs">({{ availabilityLabel }})</span>
  </span>
  <p v-else-if="params.availability">-</p>
</template>

<script>
import { OfferObjectAvailability } from '@/api/v2/requests/offers';
import { formatCurrency } from '@/util/currency';

export default {
  computed: {
    availability() {
      return this.params.availability?.local;
    },
    price() {
      return this.availability?.price;
    },
    availabilityStatus() {
      return this.params.contract
        ? OfferObjectAvailability.IN_CONTRACT
        : OfferObjectAvailability.IN_OFFER;
    },
    availabilityLabel() {
      if (
        this.availability?.secondHalfAvailability === this.availabilityStatus &&
        this.availability?.firstHalfAvailability === this.availabilityStatus
      )
        return 'all';

      if (this.availability?.firstHalfAvailability === this.availabilityStatus)
        return '1/2';

      return '2/2';
    },
  },
  methods: {
    formatCurrency,
  },
};
</script>
