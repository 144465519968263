import { defineComponent, ref, onMounted, computed, watch, } from '@vue/composition-api';
import { BTN_THEMES } from '@/store/modules/ui';
import { getUserSurveysList } from '@/api/v2/requests/surveys';
import SurveyBanner from './SurveyBanner.vue';
import SurveyModal from './SurveyModal.vue';
export default defineComponent({
    name: 'Surveys',
    components: {
        SurveyBanner,
        SurveyModal,
    },
    setup() {
        const isLoaded = ref(false);
        const wasModalClosed = ref(false);
        const wasBannerClosed = ref(false);
        const nodes = ref([]);
        const totalCount = ref(0);
        const refreshSurveys = async () => {
            isLoaded.value = false;
            const { nodes: surveys, totalCount: allSurveysCount } = await getUserSurveysList();
            nodes.value = surveys;
            totalCount.value = allSurveysCount;
            isLoaded.value = true;
        };
        onMounted(() => {
            refreshSurveys();
        });
        watch(totalCount, async (newCount) => {
            if (newCount > 0 && nodes.value.length === 0) {
                refreshSurveys();
            }
        });
        const removeSurvey = (id) => {
            totalCount.value -= 1;
            nodes.value = [...nodes.value.filter(({ survey }) => survey.id !== id)];
        };
        const shouldDisplayBanner = computed(() => isLoaded.value && wasModalClosed.value && !wasBannerClosed.value);
        return {
            nodes,
            totalCount,
            isLoaded,
            wasModalClosed,
            shouldDisplayBanner,
            wasBannerClosed,
            refreshSurveys,
            BTN_THEMES,
            removeSurvey,
        };
    },
});
