<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      :id="id"
      ref="form"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="reset"
    >
      <main-fields
        :form-type="formType"
        :mode="mode"
        :loading="loading"
        @set-values="formValues = $event"
      />

      <form-footer :loading="loading" :mode="mode" :last-route="lastRoute" />
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { FORM_MODE, FORM_TYPE } from '@/constants/form';
import MainFields from '@/views/Brand/Settings/Forms/Components/MainFields';
import FormFooter from '@/views/Brand/Settings/Forms/Components/FormFooter';
import { getPropertyArray } from '@/util/formatters';
import { getUpdatedValues } from '@/util/form';

export default {
  components: {
    MainFields,
    FormFooter,
    ValidationObserver,
  },
  props: {
    categoryId: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    lastRoute: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      required: true,
      validate: (type) => Object.values(FORM_TYPE).includes(type),
    },
    mode: {
      type: String,
      required: true,
      validate: (mode) => Object.values(FORM_MODE).includes(mode),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_TYPE,
      formValues: {},
    };
  },
  computed: {
    ...mapState('brand', {
      currentForm: 'currentForm',
    }),
    ...mapGetters('brand', ['currentBrandId']),
    brandId() {
      return this.currentBrandId(this.$route);
    },
    selectedCategoryId() {
      return this.currentForm?.category?.id || this.categoryId || null;
    },
    defaultPayload() {
      return {
        categoryId: this.selectedCategoryId,
      };
    },
    currentFormValues() {
      if (this.mode === FORM_MODE.ADD) return {};

      return this.getCurrentValues(this.currentForm);
    },
    payload() {
      const data = {
        type: this.formType,
        ...this.defaultPayload,
        ...this.formValues,
        ...(this.mode === FORM_MODE.ADD && { brandId: this.brandId }),
      };

      return {
        data: getUpdatedValues(this.currentFormValues, data),
        ...(this.mode === FORM_MODE.EDIT && { formId: this.currentForm.id }),
      };
    },
  },
  methods: {
    getCurrentValues(form) {
      return {
        ...form,
        categoryId: form.category?.id || null,
        decisionMakersIds: getPropertyArray(form.decisionMakers, 'id'),
      };
    },
    async onSubmit() {
      this.$emit('submit', {
        payload: {
          [this.formType]: this.payload,
        },
      });
    },
  },
};
</script>
