export const mapQueryToApiV2 = (query) => {
    const { sortBy, limit, page, ...rest } = query;
    return {
        ...rest,
        sort: sortBy?.[0] === '+' ? sortBy.slice(1) : sortBy,
        perPage: limit,
        page: (page && typeof page === 'string' ? parseInt(page) : page) ?? 1,
    };
};
export const mapPaginationToV1Format = (perPage, page, totalCount) => {
    const from = (page - 1) * perPage + 1;
    const to = from + perPage - 1;
    return {
        total: totalCount,
        from,
        to,
        lastPage: Math.ceil(totalCount / perPage),
    };
};
