import api from '@/api/v2';
export var QuestionType;
(function (QuestionType) {
    QuestionType["TEXT"] = "text";
    QuestionType["RADIO"] = "radio";
    QuestionType["CHECKBOX"] = "checkbox";
    QuestionType["FILE"] = "file";
})(QuestionType || (QuestionType = {}));
export const getUserSurveysList = async () => {
    const { data } = await api.get('/surveys/my?isFulfilled=false');
    return data;
};
export const submitSurveyAnswers = async (id, answers) => {
    const { data } = await api.post(`/surveys/${id}/answers`, { answers });
    return data;
};
