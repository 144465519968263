import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { createObjectInOfferDetailsRoute } from '@/router/utils';
import { useBrand } from '@/composables';
import { GenericAGTable } from '@/components/Table/AGTable/AGTable.vue';
import ObjectAddressCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ObjectAddressCell.vue';
import { getObjectsList } from '@/api/v2/requests/objects';
import NoResults from '@/views/_components/NoResults.vue';
import TablePaginationV2 from '@/views/_components/Table/TablePaginationV2.vue';
import AvailabilityCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/AvailabilityCell.vue';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import { useReservationColumns } from '@/views/Brand/Applications/Offer/Details/composables/use-reservation-columns';
import ReservationDecisionCell from '@/views/Brand/Applications/Components/Offers/Costs/_components/ReservationDecisionCell.vue';
import OfferExpandableCard from '@/views/Brand/Applications/Components/Offers/OfferExpandableCard.vue';
import FloatingTextFilter from '@/components/Table/AGTable/_components/FloatingTextFilter.vue';
import FloatingSelectFilter from '@/components/Table/AGTable/_components/FloatingSelectFilter.vue';
import ObjectNumberCell from '@/views/Brand/Applications/Components/Offers/ObjectNumberCell.vue';
const { useState } = createNamespacedHelpers('offer');
export default defineComponent({
    components: {
        OfferExpandableCard,
        TablePaginationV2,
        TableLoader,
        NoResults,
        AgTable: GenericAGTable(),
        // eslint-disable-next-line vue/no-unused-components
        ReservationDecisionCell,
        // eslint-disable-next-line vue/no-unused-components
        ObjectAddressCell,
        // eslint-disable-next-line vue/no-unused-components
        AvailabilityCell,
        // eslint-disable-next-line vue/no-unused-components
        FloatingTextFilter,
        // eslint-disable-next-line vue/no-unused-components
        FloatingSelectFilter,
        // eslint-disable-next-line vue/no-unused-components
        ObjectNumberCell,
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { currentOffer } = useState(['currentOffer']);
        const isLoading = ref(false);
        const rows = ref([]);
        const totalCount = ref();
        const getObjectRoute = (object) => {
            const { id, objectType } = object;
            if (!objectType || !brand.value || !currentOffer.value)
                return '';
            return createObjectInOfferDetailsRoute(brand.value.id, id, objectType.id, currentOffer.value.id);
        };
        const { columns } = useReservationColumns(ctx, currentOffer.value);
        const url = computed(() => {
            return `objects`;
        });
        const page = ref(1);
        const objectQuery = computed(() => {
            return {
                page: page.value,
                offerId: currentOffer.value.id,
                perPage: 500,
                withReservation: true,
            };
        });
        const fetchItems = async () => {
            isLoading.value = true;
            try {
                const { nodes, totalCount: nodesCount } = await getObjectsList(objectQuery.value ?? {});
                totalCount.value = nodesCount;
                rows.value = nodes;
            }
            catch (err) {
                ctx.root.$toast.error(ctx.root.$t(`offer-costs.error-fetching`));
            }
            finally {
                isLoading.value = false;
            }
        };
        const getRowClass = (params) => {
            if (params?.data?.isRejected) {
                return 'bg-neutral text-gray-dark';
            }
        };
        onMounted(async () => {
            await fetchItems();
        });
        watch(page, async () => {
            await fetchItems();
        });
        return {
            page,
            url,
            columns,
            getObjectRoute,
            objectQuery,
            rows,
            getRowClass,
            isLoading,
            totalCount,
        };
    },
});
