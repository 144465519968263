import apiV2 from '@/api/v2';
export const getObjectsList = async (params) => {
    const { data } = await apiV2.get('/objects', {
        params,
    });
    return data;
};
export const addObjectTag = async (data) => {
    return apiV2.post('/object-tags', data);
};
export const deleteObjectTag = async (tagId, objectId) => {
    return apiV2.delete(`/object-tags/${tagId}/object/${objectId}`);
};
export const getSingleObject = async (objectId, params) => {
    const { data } = await apiV2.get(`objects/${objectId}`, {
        params,
    });
    return data;
};
