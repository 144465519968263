var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',{attrs:{"fill-height":""}},[_c('portal',{attrs:{"to":_vm.PortalTarget.SIDEBAR}},[_c('map-sidebar',{attrs:{"disable-fields":!_vm.areAllDataSetsDelivered}})],1),_c('div',{staticClass:"h-full pb-12"},[_c('div',{staticClass:"flex items-center justify-start"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(_vm._s(_vm.$t('map-view.heading')))]),_c('filter-toggler',{staticClass:"ml-2"})],1),(_vm.brand && _vm.brand.id)?_c('clustered-map',{attrs:{"height":"h-full","data-sets":[
        {
          name: 'places',
          url: _vm.computedPlacesUrl,
          query: _vm.computedPlacesQuery,
          type: _vm.MapDataType.PLACE,
          fetchAll: !_vm.routeQuery.placeId,
          labelAccessor: ['number'],
          labelFormatter: function (label) { return ("#" + label); },
        },
        {
          name: 'objects',
          url: 'objects-map',
          query: _vm.computedObjectsQuery,
          type: _vm.MapDataType.OBJECT,
          fetchAll: true,
          apiV2: true,
          labelAccessor: ['internalNumber'],
          labelFormatter: function (label) { return ("#" + label); },
        } ]},on:{"data-status-change":function($event){_vm.areAllDataSetsDelivered = $event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }