var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expandable-row-preview',{attrs:{"id":_vm.objectId,"url":("objects/" + _vm.objectId),"sidebar":_vm.sidebar,"hide-details-btn":_vm.hideDetailsBtn,"get-title":_vm.getAddress,"api-v2":""},on:{"open-details":function($event){return _vm.$router.push({
      name: 'ObjectDetails',
      params: { objectId: _vm.objectId, objectTypeId: _vm.objectTypeId },
    })}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
    var data = ref.data;
return [_c('create-report-with-object-button',{staticClass:"mt-2 md:ml-4 md:mt-0",attrs:{"object":data,"theme":_vm.BtnTheme.PRIMARY_OUTLINE}})]}},{key:"details",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"lg:flex gap-6"},[_c('div',[_c('ul',[_c('li',{staticClass:"info"},[_c('label',[_vm._v(_vm._s(_vm.$t("object-preview.internal-number"))+":")]),_c('p',[_vm._v(_vm._s(data.internalNumber))])]),(data.place)?_c('li',{staticClass:"info"},[_c('label',[_vm._v(_vm._s(_vm.$t("object-preview.place"))+":")]),_c('p',[_c('router-link',{staticClass:"text-primary",attrs:{"to":{
                  name: 'PlaceDetails',
                  params: { placeId: data.place.id },
                }}},[_vm._v(" "+_vm._s(data.place.number)+" ")])],1)]):_vm._e(),_c('li',{staticClass:"info"},[_c('label',[_vm._v(_vm._s(_vm.$t("object-preview.coordinates"))+":")]),_c('p',[_vm._v(_vm._s(data.lat)+", "+_vm._s(data.lng))])]),_c('li',{staticClass:"info"},[_c('label',[_vm._v(_vm._s(_vm.$t("object-preview.monthly-price"))+":")]),_c('p',[_vm._v(_vm._s(data.monthlyPrice)+" PLN")])])]),_c('div',{staticClass:"info mt-6"},[_c('label',[_vm._v(_vm._s(_vm.$t("object-preview.video"))+":")]),_c('p',[(data.videoUrl)?_c('a',{staticClass:"text-blue-700",attrs:{"target":"blank","href":data.videoUrl}},[_vm._v(" "+_vm._s(data.videoUrl)+" ")]):_c('span',[_vm._v("-")])])])]),_c('ul',_vm._l((_vm.objectProperties),function(property){return _c('li',{key:property.key,staticClass:"info mt-6"},[_c('label',[_vm._v(_vm._s(property.label))]),_c('p',[_vm._v(" "+_vm._s(property.getter ? property.getter(data) : data[property.key] || '-')+" ")])])}),0)])]}},{key:"default",fn:function(){return [(!_vm.sidebar)?_c('div',{staticClass:"flex w-1/2"},[_c('select-input',{staticClass:"mt-4",attrs:{"id":"object-tags","url":"object-tags","option-label-name":"name","option-value-name":"id","placeholder":_vm.$t('place-preview.tags-placeholder'),"autocomplete":"","multiple":"","clearable":"","api-v2":""},on:{"input":_vm.handleTagsUpdate,"enter":_vm.handleEnter},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }