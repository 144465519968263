import { computed, defineComponent, watch, ref, onMounted, } from '@vue/composition-api';
import { OFFER_STATUS_COLORS } from '@/constants/offer';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric';
import { useRouteQuery } from '@/composables';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import NoResults from '@/views/_components/NoResults.vue';
import { useOfferColumns } from '@/views/Brand/Applications/Offer/_composables/use-offer-columns';
import { GenericAGTable } from '@/components/Table/AGTable/AGTable.vue';
import { getOfferList } from '@/api/v2/requests/offers';
import FloatingTextFilter from '@/components/Table/AGTable/_components/FloatingTextFilter.vue';
import OfferStatusCell from '@/views/Brand/Applications/Components/Offers/OfferStatusCell.vue';
import FloatingSelectFilter from '@/components/Table/AGTable/_components/FloatingSelectFilter.vue';
import { TablePagination } from '@/views/_components/Table';
import { mapPaginationToV1Format, mapQueryToApiV2 } from '@/util/query';
export default defineComponent({
    components: {
        TablePagination,
        NoResults,
        TableLoader,
        // eslint-disable-next-line vue/no-unused-components
        FloatingTextFilter,
        // eslint-disable-next-line vue/no-unused-components
        OfferStatusCell,
        // eslint-disable-next-line vue/no-unused-components
        FloatingSelectFilter,
        AgTable: GenericAGTable(),
    },
    setup(_, ctx) {
        const params = ctx.root.$route.params;
        const { routeQuery } = useRouteQuery(ctx);
        const isLoading = ref(false);
        const rows = ref([]);
        const totalCount = ref(0);
        const query = computed(() => {
            return {
                ...mapQueryToApiV2(routeQuery.value),
                brandId: params.id,
            };
        });
        const pagination = computed(() => {
            const { limit, page } = routeQuery.value;
            return mapPaginationToV1Format(limit, page, totalCount.value);
        });
        const fetchItems = async () => {
            isLoading.value = true;
            try {
                const { nodes, totalCount: nodesCount } = await getOfferList(query.value ?? {});
                totalCount.value = nodesCount;
                rows.value = nodes;
            }
            catch (err) {
                ctx.root.$toast.error(ctx.root.$t(`brand-offers.errorFetching`));
            }
            finally {
                isLoading.value = false;
            }
        };
        onMounted(async () => {
            await fetchItems();
        });
        const { columns } = useOfferColumns(ctx);
        watch(query, () => {
            fetchItems();
        });
        return {
            columns,
            query,
            genericTableRowSize,
            OFFER_STATUS_COLORS,
            rows,
            totalCount,
            isLoading,
            pagination,
        };
    },
});
