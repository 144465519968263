import ObjectAccept from '@/views/Brand/Objects/_components/Offer/ObjectAccept.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useOffer } from '@/composables/user-offer';
import { getSingleObject } from '@/api/v2/requests/objects';
import { usePromise } from '@/composables';
export default defineComponent({
    components: { ObjectAccept },
    props: {
        offerObject: {
            type: Boolean,
            default: false,
        },
        objectId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { currentOffer } = useOffer();
        const query = computed(() => {
            if (props.offerObject)
                return { offerId: currentOffer.value.id };
            return {};
        });
        const key = ref(0);
        const getData = () => getSingleObject(props.objectId, query.value);
        const { loading, result: objectData, fetchData } = usePromise(getData());
        const updateData = async () => {
            await fetchData(getData());
            key.value++;
        };
        const exposure = computed(() => {
            return props.offerObject
                ? (objectData.value?.availability || [])
                    .map((availability) => availability.local)
                    .reduce((acc, availability) => acc + (availability?.price ?? 0), 0)
                : objectData.value?.saleMonthlyPrice ?? 0;
        });
        const showAcceptanceBtns = computed(() => {
            return props.offerObject && currentOffer.value?.isApprovalRequired;
        });
        return {
            key,
            loading,
            objectData,
            updateData,
            exposure,
            showAcceptanceBtns,
        };
    },
});
