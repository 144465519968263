import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { QuestionType } from '@/api/v2/requests/surveys';
import { TextInput } from '@/components/Inputs/TextInput';
import { RadioGroup } from '@/components/Inputs/Radio';
import { CheckboxGroup } from '@/components/Inputs';
import { FileDropZone } from '@/components/FileDropZone';
import { confirmFileUpload, createFileUpload, uploadFileToS3, } from '@/api/v2/requests/files';
import DeleteSvg from '@/assets/icons/delete.svg';
import { Icon } from '@/components/Icon';
export default defineComponent({
    name: 'SurveyQuestion',
    props: {
        idx: { type: Number, required: true },
        question: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const id = computed(() => `q-${props.idx}`);
        const files = ref([]);
        watch(files.value, (files) => {
            props.question.value = files.join(';;;');
        });
        const uploadFile = async (file) => {
            const fileUploadHandle = await createFileUpload({
                fileName: file.name,
                fileSize: file.size,
            });
            const { url } = await uploadFileToS3(file, fileUploadHandle);
            await confirmFileUpload(fileUploadHandle.fileId);
            return url;
        };
        const setCheckboxValue = ($e) => {
            Array.from($e).forEach((checked, idx) => {
                props.question.options[idx].isSelected = checked;
            });
        };
        const handleAddFiles = async (event) => {
            const eventFiles = Array.from(event.target.files);
            if (eventFiles.length > 0) {
                if (props.question.allowMultiple) {
                    const urls = await Promise.all(eventFiles.map(uploadFile));
                    files.value.push(...urls);
                }
                else {
                    const [file] = eventFiles;
                    const url = await uploadFile(file);
                    files.value.splice(0, files.value.length, url);
                }
            }
        };
        const removeFile = (file) => {
            files.value.splice(files.value.indexOf(file), 1);
        };
        const radioOptions = computed(() => props.question.options);
        const checkboxOptions = computed(() => props.question.options);
        return {
            id,
            files,
            QuestionType,
            DeleteSvg,
            handleAddFiles,
            removeFile,
            setCheckboxValue,
            radioOptions,
            checkboxOptions,
        };
    },
    components: {
        TextInput,
        RadioGroup,
        CheckboxGroup,
        FileDropZone,
        Icon,
    },
});
