import { computed } from '@vue/composition-api';
import { useCommonColumns } from '@/views/Brand/Applications/Offer/Details/composables/use-common-columns';
export const useContractColumns = (ctx, offer, contract) => {
    const { commonColumns } = useCommonColumns(ctx, { offer, contract: true });
    const columns = computed(() => {
        return [
            ...commonColumns.value,
            {
                field: 'id',
                colId: 'cost',
                headerName: ctx.root.$t('offer-costs.totalCost').toString(),
                cellRenderer: 'TotalCostCell',
                cellRendererParams: (params) => {
                    const summaryCell = !!params?.node?.rowPinned;
                    const cost = summaryCell
                        ? contract.value?.totalSum
                        : params?.data?.availability
                            ?.map((availability) => availability.local?.price || 0)
                            .reduce((price, sum) => price + sum, 0);
                    return {
                        cost,
                        summaryCell,
                    };
                },
                width: 200,
                pinned: 'right',
                suppressMovable: true,
            },
        ];
    });
    return { columns };
};
