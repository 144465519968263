<template>
  <span class="flex flex-col justify-center h-full">
    <span class="h-4 flex items-center text-sm">{{ params.city }}</span>
    <span class="h-4 flex items-center text-2xs">{{ params.address }}</span>
  </span>
</template>

<script>
export default {};
</script>
