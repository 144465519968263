export var OfferObjectStatus;
(function (OfferObjectStatus) {
    OfferObjectStatus["ACCEPTED"] = "accepted";
    OfferObjectStatus["REJECTED"] = "rejected";
})(OfferObjectStatus || (OfferObjectStatus = {}));
export var OfferObjectDecision;
(function (OfferObjectDecision) {
    OfferObjectDecision["Accepted"] = "accepted";
    OfferObjectDecision["Rejected"] = "rejected";
    OfferObjectDecision["Unavailable"] = "unavailable";
    OfferObjectDecision["None"] = "none";
})(OfferObjectDecision || (OfferObjectDecision = {}));
